import { useParams } from "react-router-dom";

const useUrlParams = () => {

    const params = useParams();
    const { mission_name, mission_id, dashboard, scenario_id, sat_id, tab_name, truetwin_id, sat_grp_id } = params

    return {
        mission_name,
        mission_id,
        dashboard,
        scenario_id,
        sat_id,
        tab_name,
        truetwin_id,
        sat_grp_id
    }
}
export default useUrlParams