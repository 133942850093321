
import * as Cesium from "cesium";
import { createContext, useEffect, useState } from "react";

type ViewerContextType = {
    viewer: Cesium.Viewer | null;
    set_viewer: (viewer: Cesium.Viewer) => void;
    focused_ground_station: string | null;
    set_focused_ground_station: (station: string | null) => void;
}

export const ViewerContext = createContext<ViewerContextType | undefined>(undefined)
const ViewerProvider = ({ children }: { children: React.ReactNode }) => {
    const [viewer, set_viewer] = useState<Cesium.Viewer | null>(null)
    const [focused_ground_station, set_focused_ground_station] = useState<string | null>(null)

    let Imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'dark-v10',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
    });

    useEffect(() => {
        if (!viewer) {
            const cesium_viewer = new Cesium.Viewer('scenariocesiumContainer', {
                requestRenderMode: true,
                shouldAnimate: true,
                animation: false, // Disable the default animation widget
                baseLayerPicker: false, // Disable the default base layer picker
                timeline: false, // Disable the timeline
                geocoder: false, // Disable the geocoder
                homeButton: false, // Disable the home button
                navigationHelpButton: false, // Disable the navigation help button
                sceneModePicker: false, // Disable the scene mode picker
                selectionIndicator: false, // Disable the selection indicator
                fullscreenButton: false,
                imageryProvider: Imaginary,
            });

            const map_details = JSON.parse(sessionStorage.getItem('map_details') || '{}');

            cesium_viewer.scene.morphTo2D(0)
            cesium_viewer.scene.screenSpaceCameraController.inertiaZoom = 35500000;
            cesium_viewer.scene.screenSpaceCameraController.maximumZoomDistance = 35500000;
            var imaginary = new Cesium.MapboxStyleImageryProvider({
                styleId: 'dark-v10',
                accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
            });
            cesium_viewer.imageryLayers.addImageryProvider(imaginary);

            cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
            cesium_viewer.scene.globe.showGroundAtmosphere = false;
            cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
            cesium_viewer.clock.shouldAnimate = true;
            cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
            cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
            cesium_viewer.scene.highDynamicRange = false;
            setTimeout(() => {
                cesium_viewer && cesium_viewer.scene.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(0, 0, 35500000),
                    orientation: {
                        heading: Cesium.Math.toRadians(0.0),
                        pitch: Cesium.Math.toRadians(0.0),
                        roll: Cesium.Math.toRadians(0.0)
                    }
                });
            }, 1000)
            set_viewer(cesium_viewer)

            // return () => {
            //     cesium_viewer.destroy();
            // };
        }
    }, [viewer])

    return (
        <ViewerContext.Provider value={{
            viewer,
            set_viewer,
            focused_ground_station,
            set_focused_ground_station,
        }}>
            {children}
        </ViewerContext.Provider>
    )
}

export default ViewerProvider