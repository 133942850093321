import { toast } from "react-toastify";
import GroundStationService from "../../../service/GroundStationService";
import MissionService from "../../../service/MissionService";
import { AdditionalOrbitFormType, SatelliteOrbitType, ScenarioResponseType, SelectedSatelliteType } from "./types";
import dayjs from "dayjs";

export function getYears(start: number, end: number) {
    const years: number[] = [];
    for (let year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
}

export const minimise_scenario_modal = () => {
    const body = document.getElementsByClassName('create__scenario__body')
    const footer = document.getElementsByClassName('create__scenario__footer')
    if (body[0] instanceof HTMLElement && footer[0] instanceof HTMLElement) {
        if (body[0].style.display === 'none' && footer[0].style.display === 'none') {
            body[0].style.display = 'flex'
            footer[0].style.display = 'flex'
        } else {
            body[0].style.display = 'none'
            footer[0].style.display = 'none'
        }
    }
}
export const minimise_object_modal = () => {
    const body = document.getElementsByClassName('create__additional__object__body')
    const footer = document.getElementsByClassName('create__additional__object__footer')
    if (body[0] instanceof HTMLElement && footer[0] instanceof HTMLElement) {
        if (body[0].style.display === 'none' && footer[0].style.display === 'none') {
            body[0].style.display = 'flex'
            footer[0].style.display = 'flex'
        } else {
            body[0].style.display = 'none'
            footer[0].style.display = 'none'
        }
    }
}
export const maximise_object_modal = () => {
    const body = document.getElementsByClassName('create__additional__object__body')
    const footer = document.getElementsByClassName('create__additional__object__footer')
    if (body[0] instanceof HTMLElement && footer[0] instanceof HTMLElement) {
        if (body[0].style.display === 'none' && footer[0].style.display === 'none') {
            body[0].style.display = 'flex'
            footer[0].style.display = 'flex'
        }
    }
}

const get_global_groundstations = (mission_gs_list: string[]): Promise<any> => {
    return new Promise((resolve, reject) => {
        GroundStationService.getGroundStationsByMissionId()
            .then((res) => {
                if (res.data) {
                    const gs_list = res?.['data'].filter(gs =>
                        mission_gs_list.includes(gs?.['groundStationId'])
                    );
                    resolve(gs_list);
                } else {
                    resolve([]);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const get_mission_details = (mission_id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        MissionService.get_mission_by_id(mission_id)
            .then((res) => {
                if (res?.data?.['gsList']?.length > 0) {
                    const gs_list = res.data['gsList'];
                    return get_global_groundstations(gs_list);
                }
                return [];
            })
            .then((groundStations) => {
                resolve(groundStations);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const get_station_ids = (all_stations: any[]) => {
    return all_stations.map((station) => station?.['groundStationId']);
};

export const get_ground_station_by_id = (ground_stations: any[], grounStationId: string) => {
    const hoveredGsId = ground_stations.find(station => station.groundStationId === grounStationId)
    return hoveredGsId;
}

export const orbit_field_names: {
    [key: string]: {
        label: string,
        min: number,
        max: number
    }
} = {
    altitude: {
        label: 'Altitude',
        min: 300,
        max: 750
    },
    eccentricity: {
        label: 'Eccentricity',
        min: 0,
        max: 0.05
    },
    inclination: {
        label: 'Inclination',
        min: 0,
        max: 180
    },
    RAAN: {
        label: 'Right Ascension of the Ascending Node (RAAN)',
        min: 0,
        max: 360
    },
    AP: {
        label: 'Argument of Periapsis (AP)',
        min: 0,
        max: 360
    },
    TA: {
        label: 'True Anomaly(TA)',
        min: 0,
        max: 360
    }
}

export const validate_scenario_time = (date: number) => {
    // check the date is valid or not
    if (!date) {
        toast.error("Please enter valid scenario start time.", { toastId: "scenario_start_time" })
        return false
    } else if (dayjs(date * 1000).year() < 2002 || dayjs(date * 1000).year() > 2037) {
        toast.error("Year should be between 2002 and 2037.", { toastId: "scenario_start_time" })
        return false
    } else {
        return true
    }
}

export const validate_simulation_time = (date: number, duration: number | string, scenario_start_time: number, scenario_duration: number) => {
    // check the date is valid or not
    if (!date) {
        toast.error("Please enter valid simulation start time", { toastId: "simulation_start_time" })
        return false
    } else if (!duration || duration as number < 10 || duration as number > 1440) {
        toast.error(!duration ? "Please enter valid duration." : "Duration should be between 10 and 1440 minutes.", { toastId: "simulation_duration" })
        return false
    } else if (date < scenario_start_time || (date + (duration as number) * 60) > (scenario_start_time + (scenario_duration as number) * 60)) {
        toast.error((date < scenario_start_time) ? "Simulation start time should be greater than scenario start time." : "The simulation window should be limited to a 24-hour period from the scenario's start time.", { toastId: "simulation_start_time" })
        return false
    } else {
        return true
    }
}

export const validate_orbit_details = (orbit_details: AdditionalOrbitFormType | SatelliteOrbitType | null) => {
    if (!orbit_details) {
        return toast.error('Orbit details are required.', { toastId: '0' })
    }
    const not_validated = Object.keys(orbit_details).some((key: string | number) => {
        if (key === 'altitude' && ((orbit_details?.[key] === '') || orbit_details?.[key] as number < 300 || orbit_details?.[key] as number > 750)) {
            toast.error(orbit_details?.[key] === '' ? "Altitude is a required field and must be provided." : "Altitude should be between 300 and 750 km", { toastId: "o" })
            return true;
        } else if (orbit_details?.[key] === '') {
            toast.error(`${orbit_field_names[key].label} of Periapsis is a required field and must be provided.`, { toastId: "o" })
            return true;
        } else if (orbit_details?.[key] as number < orbit_field_names?.[key]?.min || orbit_details?.[key] as number > orbit_field_names?.[key]?.max) {
            toast.error(`${orbit_field_names[key].label} should be between ${orbit_field_names?.[key].min} and ${orbit_field_names?.[key].max}.`, { toastId: "0" })
        }
    });
    return not_validated ? false : true
}


type Satellite = {
    groupId: string;
    orbit_params: {
        altitude: number;
        eccentricity: number;
        inclination: number;
        RAAN: number;
        AP: number;
        TA: number;
        epoch: number;
    };
}

export const check_same_name = (previous_name: string, updated_name: string) => {
    return previous_name === updated_name;
}

export const check_same_satellite = (previous_satellite: Satellite, updated_satellite: Satellite) => {
    const previous_orbit_params = previous_satellite.orbit_params;
    const updated_orbit_params = updated_satellite.orbit_params;
    return (
        previous_satellite.groupId === updated_satellite.groupId &&
        previous_orbit_params.epoch === updated_orbit_params.epoch &&
        previous_orbit_params.altitude === updated_orbit_params.altitude &&
        previous_orbit_params.eccentricity === updated_orbit_params.eccentricity &&
        previous_orbit_params.inclination === updated_orbit_params.inclination &&
        previous_orbit_params.RAAN === updated_orbit_params.RAAN &&
        previous_orbit_params.AP === updated_orbit_params.AP &&
        previous_orbit_params.TA === updated_orbit_params.TA
    );
}

export const check_same_ground_stations = (previous_ground_stations: string[], updated_ground_stations: string[]) => {
    return previous_ground_stations.every((gs, index) => gs === updated_ground_stations[index]);
}

export const set_edited_scenario_data = (step: string, scenario: ScenarioResponseType) => {
    if (step === 'satellite' && scenario?.satelliteGroups.length > 0) {
        const satellite: SelectedSatelliteType = {
            label: scenario?.satelliteGroups[0].satelliteGroupName,
            value: {
                groupId: scenario?.satelliteGroups[0].satelliteGroupId,
                groupType: scenario?.satelliteGroups[0].satelliteGroupType,
                satCount: scenario?.satelliteGroups[0].satelliteCount,
                groupName: scenario?.satelliteGroups[0].satelliteGroupName,
                planePhasing: scenario?.satelliteGroups[0].satellitesPhaseConfiguration,
                orbit_data: scenario?.satelliteGroups[0].groupReferenceOrbit,
            }
        }
        return satellite;
    } else if (step === 'scenario_time' && scenario?.scenarioStartTime) {
        return scenario?.scenarioStartTime;
    } else if (step === 'scenario_duration' && scenario?.scenarioDuration) {
        return scenario?.scenarioDuration;
    } else if (step === 'simulation_time' && scenario?.simulationStartTime) {
        return scenario?.simulationStartTime;
    } else if (step === 'simulation_duration' && scenario?.simulationDuration) {
        return scenario?.simulationDuration;
    } else if (step === 'ground_station' && scenario?.groundStations.length > 0) {
        return scenario?.groundStations;
    }
}


export function get_all_task_plans(groupId: string): Promise<{
    label: string;
    value: any;
}[]> {
    return new Promise((resolve, reject) => {
        MissionService.getAllTaskPlan(groupId)
            .then((response) => {
                if (response?.data && response.data.length > 0) {
                    const taskPlanOptions = response.data.map((taskPlan) => ({
                        label: taskPlan.taskPlanName,
                        value: taskPlan
                    }));
                    resolve(taskPlanOptions);
                } else {
                    resolve([]);
                }
            })
            .catch((error) => {
                reject(new Error('Failed to fetch task plans: ' + error));
            });
    });
}
