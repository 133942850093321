import { FC } from "react"
import { AdcsIcon, EPSSvg } from "../../svgs/Svgs"
import '../ModalStyle.scss'

const BUS_ITEMS = [
    {
        name: 'ADCS',
        icon: <AdcsIcon />
    },
    {
        name: 'EPS',
        icon: <EPSSvg />
    },
]

interface BusFormProps {
    bus_type: string,
    set_bus_type: (value: string) => void
}

const BusForm: FC<BusFormProps> = ({ bus_type, set_bus_type }) => {
    return (
        <div className='bus__form__container'>
            {BUS_ITEMS.map(item => (
                <div className="bus__form__item">
                    <div className="d-flex gap-3">
                        {item.icon}
                        {item.name}
                    </div>
                    <div className={`check__button__container ${bus_type === item.name && 'check'}`}>
                        <span className="button_text">HWIL</span>
                        <button className="toggle__button__container" onClick={() => set_bus_type(bus_type !== item.name ? item.name : '')}>
                            <div className="toggle__button" />
                            <div className="toogle__button__check" />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default BusForm