import { createContext, useRef, useState } from "react";
import ScenarioScheduleModal from "../modals/schedule/ScenarioScheduleModal";
import PowerGraphModal from "../modals/prediction/PowerGraphModal";
import ADCSGraphModal from "../modals/prediction/ADCSGraphModal";
import DataFlowGraphModal from "../modals/prediction/DataFlowGraphModal";
import useScenario from "../hooks/useScenario";

type ModalType = {
    open: boolean;
    pinned: boolean;
    current_modal: boolean | string;
    expanded: boolean;
    zIndex: number
}

type ModalProviderContextType = {
    modals: {
        'power': ModalType;
        'adcs': ModalType;
        'data_flow': ModalType;
        'schedule': ModalType;
    };
    open_modal: (modal: string) => void;
    close_modal: (modal: string) => void;
    close_unpinned_modal: () => void;
    open_confirmation_dialouge: boolean;
    open_additional_orbit_modal: boolean;
    set_open_confirmation_dialouge: (value: boolean) => void;
    set_open_additional_orbit_modal: (value: boolean) => void;
}

export const ModalContext = createContext<ModalProviderContextType | undefined>(undefined)

const ModalProvider = ({ children }: { children: React.ReactNode }) => {

    const modalRef = useRef<boolean>(false)
    const { prediction_data } = useScenario()

    const [open_additional_orbit_modal, set_open_additional_orbit_modal] = useState<boolean>(false)
    const [open_confirmation_dialouge, set_open_confirmation_dialouge] = useState<boolean>(false)

    const [modals, set_modals] = useState({
        'power': {
            open: false,
            pinned: false,
            current_modal: false,
            expanded: false,
            zIndex: 99
        },
        'adcs': {
            open: false,
            pinned: false,
            current_modal: false,
            expanded: false,
            zIndex: 99
        },
        'data_flow': {
            open: false,
            pinned: false,
            current_modal: false,
            expanded: false,
            zIndex: 99
        },
        'schedule': {
            open: false,
            pinned: false,
            current_modal: false,
            expanded: false,
            zIndex: 99
        },
    });

    const get_max_zIndex = () => {
        let max_Z = 0;
        max_Z = Object.values(modals).reduce((maxZIndex, panel) => {
            return panel.zIndex > maxZIndex ? panel.zIndex : maxZIndex;
        }, 0);
        return max_Z + 1
    }

    const get_previous_current_modal = (closed_modal: string) => {
        return Object.entries(modals).reduce((maxPanel, [key, panel]) => {
            return (panel.zIndex > maxPanel.zIndex && panel.open && closed_modal !== key) ? { name: key, zIndex: panel.zIndex } : maxPanel;
        }, { name: '', zIndex: 0 });
    }

    const open_modal = (modal_name: string) => {
        const current_modal_zIndex = get_max_zIndex()
        Object.keys(modals).map((key: any) => {
            if (key === modal_name) {
                set_modals((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded, zIndex: current_modal_zIndex } }))
            } else {
                set_modals((prev) => ({ ...prev, [key]: { open: prev[key].pinned ? true : false, pinned: prev[key].pinned, current_modal: false, expanded: (prev[key].pinned && prev[key].expanded) ? prev[key].expanded : false, zIndex: prev[key].zIndex } }))
            }
        })
    };

    const set_top_modal = (modal_name: string) => {
        const current_modal_zIndex = get_max_zIndex()
        Object.keys(modals).map((key: any) => {
            if (key === modal_name) {
                set_modals((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded, zIndex: modals[modal_name].current_modal !== modal_name ? current_modal_zIndex : prev[key].zIndex } }))
            } else {
                set_modals((prev) => ({ ...prev, [key]: { open: prev[key].open ? true : false, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
            }
        })
    };

    const set_expanded = (modal_name: string) => {
        Object.keys(modals).map((key: any) => {
            if (key === modal_name) {
                set_modals((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded ? false : modal_name, zIndex: prev[key].zIndex } }))
            } else {
                set_modals((prev) => ({ ...prev, [key]: { open: prev[key].open, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
            }
        })
    };

    const close_modal = (modal_name: string) => {
        const previous_modal = get_previous_current_modal(modal_name)
        set_modals((prev) => (
            {
                ...prev,
                [modal_name]: { open: false, pinned: false, current_modal: false, expanded: false, zIndex: 99 },
                [previous_modal.name]: { ...modals[previous_modal.name], current_modal: previous_modal.name }
            }))
    };

    const pinned_modal = (modal_name: string) => {
        Object.keys(modals).map((key: string) => {
            if (key === modal_name) {
                set_modals((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? false : true, current_modal: modal_name, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
            } else {
                set_modals((prev) => ({ ...prev, [key]: { open: prev[key].open, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
            }
        })
    };

    const close_unpinned_modal = () => {
        if (modalRef.current) {
            modalRef.current = false
            return
        }
        Object.keys(modals).forEach((key) => {
            if (!modals[key].pinned) {
                set_modals((prev) => ({ ...prev, [key]: { open: false, pinned: false, current_modal: false, expanded: false, zIndex: 99 } }))
            }
        })
    }

    return (
        <ModalContext.Provider value={{
            modals,
            open_modal,
            close_modal,
            close_unpinned_modal,
            open_confirmation_dialouge,
            set_open_confirmation_dialouge,
            open_additional_orbit_modal,
            set_open_additional_orbit_modal
        }}>
            {children}

            <ScenarioScheduleModal
                pinned={modals?.['schedule']?.['pinned']}
                open={modals?.['schedule']?.['open']}
                current_modal={modals?.['schedule']?.['current_modal']}
                close_modal={close_modal}
                pinned_modal={pinned_modal}
                set_expanded={set_expanded}
                set_top_modal={set_top_modal}
                expanded={modals?.['schedule']?.['expanded']}
                zIndex={modals?.['schedule']?.['zIndex']}
                modalRef={modalRef}
            />
            <PowerGraphModal
                pinned={modals?.['power']?.['pinned']}
                open={modals?.['power']?.['open']}
                current_modal={modals?.['power']?.['current_modal']}
                close_modal={close_modal}
                pinned_modal={pinned_modal}
                set_expanded={set_expanded}
                set_top_modal={set_top_modal}
                expanded={modals?.['power']?.['expanded']}
                zIndex={modals?.['power']?.['zIndex']}
                powerAnalysisGraphUrl={prediction_data?.['power_urls']}
                modalRef={modalRef}
            />
            <ADCSGraphModal
                pinned={modals?.['adcs']?.['pinned']}
                open={modals?.['adcs']?.['open']}
                current_modal={modals?.['adcs']?.['current_modal']}
                close_modal={close_modal}
                pinned_modal={pinned_modal}
                set_expanded={set_expanded}
                set_top_modal={set_top_modal}
                expanded={modals?.['adcs']?.['expanded']}
                zIndex={modals?.['adcs']?.['zIndex']}
                adcsAgilityGraphUrl={prediction_data?.['adcs_urls']}
                modalRef={modalRef}
            />
            <DataFlowGraphModal
                pinned={modals?.['data_flow']?.['pinned']}
                open={modals?.['data_flow']?.['open']}
                current_modal={modals?.['data_flow']?.['current_modal']}
                close_modal={close_modal}
                pinned_modal={pinned_modal}
                set_expanded={set_expanded}
                set_top_modal={set_top_modal}
                expanded={modals?.['data_flow']?.['expanded']}
                zIndex={modals?.['data_flow']?.['zIndex']}
                dataFlowGraphUrl={prediction_data?.['data_flow_urls']}
                modalRef={modalRef}
            />
        </ModalContext.Provider>
    )
}

export default ModalProvider