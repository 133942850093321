import { useContext } from "react"
import { ScenarioContext } from "../providers/ScenarioProvider";

const useScenario = () => {
    const context = useContext(ScenarioContext);
    if (context === undefined) {
        throw new Error('useScenario must be used within a ScenarioProvider');
    }
    const { scenario, set_scenario, loading, simulation_time, set_simulation_time, grafana, set_grafana } = context;
    return {
        loading,
        scenario,
        set_scenario,
        simulation_time,
        set_simulation_time,
        grafana,
        set_grafana
    };
}

export default useScenario