import { Fragment, useEffect, useMemo, useState } from 'react'
import CustomToolTip from '../../CommonComponents/CustomToolTip'
import { ADCSSvg, DataFlowSvg, OnboardScheduleSvg, PinnedSvg, PowerSvg } from './svgs/Svgs'
import { CircularProgress } from '@mui/material'
import useModals from './hooks/useModals';
import useScenario from './hooks/useScenario';

const ScenarioHeader = () => {

    const { open_modal, close_modal, modals } = useModals()
    const { prediction_loading, prediction_data, schedule_data } = useScenario()
    const [show_schedule_notification, set_show_schedule_notification] = useState(false)

    const scenario_scheduled = useMemo(() => schedule_data, [schedule_data])

    useEffect(() => {
        if (schedule_data) {
            set_show_schedule_notification(true)
            setTimeout(() => {
                set_show_schedule_notification(false)
            }, 3000);
        }
    }, [schedule_data])

    const toggle_modal = (modal: string) => {
        if (modals[modal].open) {
            close_modal(modal)
        } else {
            open_modal(modal)
        }
    }

    return (
        <div className='scenario__header'>
            <div className={`right__button__container`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Schedule' placement='bottom'>
                    <button className={`right__section__button single__btn ${(modals?.['schedule'].open || show_schedule_notification) && 'active'} ${!scenario_scheduled && 'disabled'}`}
                        onClick={() => { toggle_modal('schedule') }}>
                        <OnboardScheduleSvg />
                        <PinnedSvg showPinned={modals?.['schedule'].pinned} />
                    </button>
                </CustomToolTip>
            </div>
            {/* {!prediction_loading &&
                <div className='right__button__container' onClick={(e: any) => { e.stopPropagation() }}>
                    <CustomToolTip title='Power' placement='bottom'>
                        <button className={`right__section__button left__btn ${modals?.['power'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('power') }}>
                            <PowerSvg />
                            <PinnedSvg showPinned={modals?.['power'].pinned} />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='ADCS' placement='bottom'>
                        <button className={`right__section__button center__btn ${modals?.['adcs'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('adcs') }}>
                            <ADCSSvg />
                            <PinnedSvg showPinned={modals?.['adcs'].pinned} />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='Data Flow' placement='bottom'>
                        <button className={`right__section__button right__btn ${modals?.['data_flow'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('data_flow') }}>
                            <DataFlowSvg />
                            <PinnedSvg showPinned={modals?.['data_flow'].pinned} />
                        </button>
                    </CustomToolTip>
                </div>
            }
            {prediction_loading &&
                <Fragment>
                    <div className='deploy__notification__container'>
                        <i className='fe fe-info' />
                        <span>Deploy to skip</span>
                    </div>
                    <div className='right__button__loading__container' onClick={(e: any) => { e.stopPropagation() }}>
                        {prediction_loading}
                        <CircularProgress size={10} className="color-atmos" />
                    </div>
                </Fragment>
            } */}
        </div>
    )
}

export default ScenarioHeader