import { Fragment, useMemo } from 'react'
import CustomToolTip from '../../../../CommonComponents/CustomToolTip'
import useUrlParams from '../../hooks/useUrlParams'
import { CloseSvg, ScenarioLogo } from '../../svgs/Svgs'
import { minimise_scenario_modal } from '../../utils'
import './Creation.scss'
import AdditionObject from './steps/AdditionObject'
import Details from './steps/Details'
import GroundStation from './steps/GroundStation'
import Satellite from './steps/Satellite'
import Schedule from './steps/Schedule'
import CreateObject from './CreateObject'
import useFormData from '../../hooks/useFormData'
import useScenario from '../../hooks/useScenario';
import useModals from '../../hooks/useModals';
import ConfirmationDialog from '../../../modals/confirmation_dialog/ConfirmationDialog'
import useUserData from '../../hooks/useUserData'

const CreateScenario = () => {
    const { submit_form } = useFormData()
    const { check_same_data } = useFormData()
    const { use_additional_orbit } = useUserData()
    const { loading, edit_satellite, schedule_data, imported_schedule, simulation_start_time, duration, task_plan } = useScenario()
    const { open_additional_orbit_modal, open_confirmation_dialouge, set_open_confirmation_dialouge } = useModals()
    const { close_scenario, navigate_scenario_step, scenario_step } = useUrlParams()

    const footer_left_button = scenario_step === 'details' ? 'Close' : 'Back'
    const disabled = useMemo(() => (loading || edit_satellite || open_additional_orbit_modal) ? true : false, [loading, edit_satellite, open_additional_orbit_modal])

    const show_upload_schedule_button = useMemo(() => (scenario_step === 'schedule' && imported_schedule) ? true : false, [imported_schedule, scenario_step])
    const upload_schedule_button_text = useMemo(() => scenario_step === 'schedule' ? 'Upload Schedule' : 'Deploy', [imported_schedule])
    const next_button_text = useMemo(() => loading ? loading : scenario_step === 'schedule' ? 'Generate Schedule' : scenario_step === 'deploy_scenario' ? 'Deploy' : 'Next', [scenario_step, loading, schedule_data, simulation_start_time, duration, task_plan])

    const close_scenario_page = () => {
        if (check_same_data()) {
            close_scenario()
        } else {
            set_open_confirmation_dialouge(true)
        }
    }

    return (
        <Fragment>
            <div className='create__scenario__container'>
                <div className='create__scenario__header'>
                    <div className='d-flex gap-2 align-items-center'>
                        <ScenarioLogo />
                        <span className='header__title'>Scenario Creation</span>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                        <CustomToolTip title='Minimize' placement='top'>
                            <button className='header__button' onClick={minimise_scenario_modal}>
                                <i className='fe fe-minus' />
                            </button>
                        </CustomToolTip>
                        <CustomToolTip title='Close' placement='top'>
                            <button className='header__button' onClick={close_scenario_page}>
                                <CloseSvg />
                            </button>
                        </CustomToolTip>
                    </div>
                </div>
                <div className='create__scenario__body'>
                    <Details />
                    <Satellite />
                    {use_additional_orbit && <AdditionObject />}
                    <GroundStation />
                    <Schedule />
                </div>
                <div className='create__scenario__footer'>
                    <button className='footer__close__button'
                        onClick={() => {
                            if (scenario_step === 'details') {
                                close_scenario()
                            } else {
                                navigate_scenario_step('backword')
                            }
                        }}
                    >{footer_left_button}</button>
                    {!show_upload_schedule_button &&
                        <button
                            disabled={disabled}
                            className='footer__next__button'
                            onClick={submit_form}
                        >{next_button_text} {loading && <div className='spin__loader' />}</button>
                    }
                    {show_upload_schedule_button &&
                        <button
                            disabled={disabled}
                            className='footer__next__button'
                            onClick={() => { }}
                        >{upload_schedule_button_text} {loading && <div className='spin__loader' />}</button>
                    }
                </div>
            </div>
            <CreateObject />
            <ConfirmationDialog
                open={open_confirmation_dialouge}
                type={'discard'}
                title='Discard Scenario Creation?'
                description='Discarding this draft will result in the loss of all scenario data.'
                // onConfirm={submit_form}
                onConfirm={close_scenario}
                onCancel={() => set_open_confirmation_dialouge(false)}
                onDiscard={close_scenario}
                confirm_button_text='Save As Draft'
            />
        </Fragment>
    )
}

export default CreateScenario