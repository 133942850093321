import Select from 'react-select';
import { useMemo } from 'react';

interface CustomSelectProps {
    options: any[];
    placeholder: string;
    value: any;
    onChange: (value: any) => void;
    isDisabled?: boolean;
    border?: string;
    color?: string;
    menuHeight?: string,
    placement?: 'top' | 'bottom';
    background?: string;
    padding?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, placeholder, value, onChange, isDisabled, border, color, menuHeight, placement = 'bottom', background, padding }) => {

    const top_position = useMemo(() => {
        const menu_height = menuHeight ? `-${Number(menuHeight.split('px')[0]) + 50}px` : '-250px';
        return placement === 'top' ? menu_height : 'auto'
    }, [placement, menuHeight])

    return (
        <Select
            className={`w-100`}
            isDisabled={isDisabled}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            menuPortalTarget={document.body}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: "4px",
                    border: border || "0.5px solid rgba(240, 240, 240, 0.30)",
                    backgroundColor: background ? background : state.selectProps?.value ? "#000" : "rgba(10, 10, 10, 0.65)",
                    boxShadow: 'none',
                    minHeight: '30px',
                    padding: padding || "0px 4px",
                    '&:hover': {
                        border: border || "0.5px solid rgba(240, 240, 240, 0.30)",
                        boxShadow: 'none'
                    }
                }),
                menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#000",
                    top: top_position,
                }),
                option: (provided, state) => {
                    return {
                        ...provided,
                        fontSize: "12px !important",
                        backgroundColor: state.isSelected
                            ? "rgb(49, 53, 36)" // Highlight color for selected option
                            : "rgba(255, 255, 255, 0.15)",
                        color: state.isSelected
                            ? "#CCF54E" // Text color for selected option 
                            : "#FFFFFF", // Default text color
                        "&:hover": {
                            backgroundColor: "rgba(240, 240, 240, 0.20)",
                            color: '#FFFFFF'
                        },

                    };
                },
                indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    padding: "0px",
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: "#57585a !important",
                    fontSize: "12px !important",
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: "0px 4px",
                }),
                singleValue: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                    color: color || '#fff',
                }),
                menuList: (provided) => ({
                    ...provided,
                    maxHeight: menuHeight || "200px", // Set max-height here
                    overflowY: "auto", // Add scroll if items exceed max-height
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    borderRadius: "4px 0px 4px 0px",
                    backgroundColor: "#000",
                }),
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: color || '#fff', // Dynamically set indicator color
                    transform: state.isFocused ? "rotate(0deg)" : "rotate(90deg)", // Add rotation for active state
                    transition: "transform 0.3s ease, color 0.3s ease", // Smooth transition
                    padding: '0px',
                    '&:hover': {
                        color: color || '#fff',
                    }
                }),
            }}
        />
    )
}

export default CustomSelect