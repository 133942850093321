import { useContext } from "react"
import { ViewerContext } from "../providers/ViewerProvider";

const useViewer = () => {
    const context = useContext(ViewerContext);
    if (context === undefined) {
        throw new Error('useViewer must be used within a ViewerProvider');
    }
    return context;
}

export default useViewer