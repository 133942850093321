
import { FC, useState } from "react"
import './ModalStyle.scss'
import { ModalClose, QuickDeploy, SimulateLogo } from "../svgs/Svgs"

interface DeployTypeModalProps {
    open: boolean,
    close: () => void,
    select_deplooy_type: (type: string) => void,
}

const DeployTypeModal: FC<DeployTypeModalProps> = ({ open, close, select_deplooy_type }) => {

    const [type, set_type] = useState<string>('')

    const close_modal = () => {
        close()
        set_type('')
    }


    return (
        <div className={`deploy__model ${open && 'active'}`}>
            <div className="deploy__model__center">
                <div className="deploy__model__center__header">
                    <span className="fs-18">Deployment Mode</span>
                    <button className="header__close__button" onClick={close_modal}>
                        <ModalClose />
                    </button>
                </div>
                <div className="deploy__model__center__body">
                    <button className={`deploy__type__items ${type === 'scenario' && 'active'}`}
                        onClick={() => { set_type('scenario') }}
                    >
                        <div className="d-flex align-items-center gap-2">
                            <SimulateLogo color={'#F0F0F0'} />
                            <span className="fs-16">Scenario Simulation</span>
                        </div>
                        <span className="fs-12 opacity-75">Simulate different scenarios on a fully software-based simulation environment that mirrors the behaviour of a real satellite.</span>
                    </button>
                    <button className={`deploy__type__items ${type === 'quick_deploy' && 'active'}`}
                        onClick={() => { set_type('quick_deploy') }}
                    >
                        <div className="d-flex align-items-center gap-2">
                            <QuickDeploy />
                            <span className="fs-16">Quick Simulation</span>
                        </div>
                        <span className="fs-12 opacity-75">Quick Deploy enables the setup of scenarios for testing tele-commands and tasks in both SWIL and HWIL environments</span>
                    </button>
                </div>
                <div className="deploy__model__center__footer">
                    <button className="footer__close__button" onClick={close_modal}>
                        Close
                    </button>
                    <button className="footer__next__button" onClick={() => {
                        select_deplooy_type(type)
                        close_modal()
                    }}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeployTypeModal