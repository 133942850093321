// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { MissionStore, setPayloadSliderFalse, setPayloadSliderTrue } from '../../../../../store/MissionSlice';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePayloadUsage, calculate_Total_AOP_Usage, navigateTo } from '../../../../CommonComponents/CommonFunctions';
import { Steps } from '../../Stepper.constants';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../../../Layouts/PageHeader/PageHeader';
import PayloadSlider from './PayloadSlider';
import CloseButton from '../../../../CommonComponents/CloseButton';
import MissionService from '../../../../../service/MissionService';
import { usageStore } from '../../../../../store/UsageSlice';
import { toast } from 'react-toastify';
import Dialog from '../../../../CommonComponents/Dialog';
import Loader from '../../../../../Layouts/Loader/Loader';

const PreviewPayloads = () => {
    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, mission_id, sat_name, sat_id } = params

    console.log("Mission -> ", mission_name, mission_id, sat_name, sat_id)

    const isPayloadSlider = useSelector(
        (state: { mission: MissionStore }) => state.mission.isPayloadSlider
    );

    const [payloadSliderData, setPayloadSliderData] = useState<any>({});

    const payloadSizeUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadSizeUsage);
    const payloadWeightUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadWeightUsage);
    const payloadPowerUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadPowerUsage);

    const [selectedInterface, setSelectedInterface] = useState<any[]>([]);
    const [satPayloads, setSatPayloads] = useState<any[]>([]);
    const [satellieteDeta, setSatellieteData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [isPayloadDelete, setIsPayloadDelete] = useState(false);

    const [deletedPayloadData, setDeletedPayloadData] = useState<any>({});
    const [openDeleteModel, setOpenDeleteModal] = useState<any>(false);
    const [satName, setSatName] = useState("")

    const get_satellite = () => {
        MissionService.get_satellite_by_sat_id(sat_id, mission_id)?.then((sat) => {
            if (sat?.data) {
                setSatName(sat.data.name)
                get_payload(sat_id)
                // let payloads = sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] : []
                // setSatPayloads(payloads)
                setSatellieteData(sat.data)
                // calculatePayloadUsage(payloads, dispatch);
                // calculate_Total_AOP_Usage(payloads, [], dispatch);
            }
            setLoading(false)
        }).catch((err) => {
            console.log('Some thing went wrong while getting satellite');
            setLoading(false)
        })
    }

    const get_payload = (sat_id) => {
        MissionService.get_payload_by_sat_id(sat_id, mission_id)?.then((payload) => {
            if (payload?.data) {
                setSatPayloads(payload?.data)

                calculatePayloadUsage(payloads?.data, dispatch);
                calculate_Total_AOP_Usage(payloads?.data, [], dispatch);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (sat_id) {
            get_satellite()
        }
    }, [sat_id]);

    const update_satellite = (payloads: any) => {
        let sat = {
            ...satellieteDeta,
            name: satellieteDeta?.['name'],
            "containing-mission": satellieteDeta?.['containing-mission'],
            draftMode: 'Yes',
            versions: [
                {
                    assembly: {
                        'Payloads': payloads,
                        'Edge': satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'],
                        'Template': '',
                    },
                },
            ],
            "swap-info": {
                ...satellieteDeta?.['"swap-info"'],
                totalPayloadSize: payloadSizeUsage,
                totalPayloadMass: payloadWeightUsage,
                totalPayloadPower: payloadPowerUsage,
            },
        };

        MissionService.update_satellites(sat_id, sat)?.then((res) => {
            if (res.data) {
                toast.success('Payload deleted successfully', { toastId: 'o' });
                get_satellite()
                if (payloads.length === 0) {
                    navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_payload`, navigate)
                }
            }
        })
            .catch((err) => {
                console.log("Something went wrong while adding payload ");
                setLoading(false)
            });
    }

    const deletePreviewPayloads = (data) => {
        if (satPayloads?.length > 0) {
            setLoading(true)
            let payload = satPayloads;
            if (payload.length === 1) {
                setSatPayloads([])
                update_satellite([])
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_payload`, navigate)
            } else {
                let filterPayload = payload?.filter(item => item?.['userData']?.['id'] != data?.['userData']?.['id'])
                update_satellite(filterPayload)
                setSatPayloads(filterPayload)
                calculatePayloadUsage(filterPayload, dispatch);
                calculate_Total_AOP_Usage(filterPayload, [], dispatch);
                setIsPayloadDelete(true)
            }
        }
    }

    const navigate_to_step = (step) => {
        if (satPayloads?.length > 0 && satPayloads?.length <= 4) {
            if (step === 'Edge') {
                if (satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'] && satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge']?.length > 0) {
                    navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
                } else {
                    navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
                }
            }
        } else if (satPayloads?.length > 4) {
            toast.error("You can't add more than 4 payloads", { toastId: 'o' })
        } else {
            toast.error("Add atleast one payload to proceed", { toastId: 'o' })
        }
    }


    return <div className='h-100 w-100 d-flex flex-column overflow-hidden'>
        {loading && <Loader />}
        <PageHeader
            sat_name={satName}
            titles={"Payload Devices"}
            description={"Payloads currently configured in this satellite"}
            active="New Mission"
            items={["Apps"]}
            isButton={false}
            showSwap={true}
            showPercent={false}
            type=''
            value=''
        />
        {satPayloads?.length >= 4 && <div className='badge__info '>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
            </svg>
            <span>Maximum Payload device capacity reached. You can only add 4 Payload devices to your satellite.</span>
        </div>}

        <div className="d-flex flex-wrap flex-1 overflow-auto py-4 gap-4 ps-7 align-content-start">
            {satPayloads?.length > 0 && satPayloads?.map((v, id) => {
                let weight = v?.['atmosData']?.['weight_in_gms'];
                return (
                    <div className='common-card-box-preview m-0' key={id} style={{ width: '440px' }}>
                        <div className="common-card-header d-flex justify-content-between align-items-start">
                            <div className="w-100 d-flex gap-2 align-items-center">
                                <img
                                    src={v['atmosData']?.['src']}
                                    className="ms-2"
                                    width="30px"
                                    height="30px"
                                    alt={'icom'}
                                />
                                <div>
                                    <h4 className="mb-0" title={v?.["userData"]?.["payload_name"]}>
                                        {v?.["userData"]?.["payload_name"].length > 15 ? v?.["userData"]?.["payload_name"]?.substring(0, 15) + "..." : v?.["userData"]?.["payload_name"]}
                                    </h4>
                                    <div className="sat-sub-info">
                                        <span>
                                            {v?.['atmosData']?.['component_specific_attributes']?.['payloadType']}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-list more-button">
                                <Dropdown drop='end'>
                                    <Dropdown.Toggle variant="transparent text-white fs-20">
                                        <i className="fe fe-more-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1" onClick={() => {
                                            if (isPayloadSlider) {
                                                dispatch(setPayloadSliderFalse())
                                            }
                                            setSelectedInterface(
                                                v?.['userData']?.['selected_interfaces']
                                            );
                                            setPayloadSliderData(v);
                                            dispatch(setPayloadSliderTrue())
                                        }}>Info</Dropdown.Item>
                                        <Dropdown.Item eventKey="2"
                                            onClick={() => {
                                                if (isPayloadSlider) {
                                                    dispatch(setPayloadSliderFalse());
                                                }
                                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/edit_payload/${v?.['userData']?.['payload_name']}/${v?.['userData']?.['id']}`, navigate);
                                            }}>
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="3" onClick={() => {
                                            if (isPayloadSlider) {
                                                dispatch(setPayloadSliderFalse())
                                            }
                                            setDeletedPayloadData(v)
                                            setOpenDeleteModal(true)
                                        }
                                        }>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="common-card-content">
                            <div className="d-flex flex-wrap">
                                <div className="stats-alpha">
                                    <strong>{v?.['atmosData']?.['product-cookie']?.['Size'] + ' U'}</strong>
                                    <span>Size</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{(weight > 999) ? ((weight / 1000) + ' kg') : (weight + ' gm')}</strong>
                                    <span>Mass</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.["userData"]?.['downlinkDatasize']} MB</strong>
                                    <span>Data Per Pass</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.["userData"]?.['time_per_orbit']} min</strong>
                                    <span>Time Per Orbit</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.["userData"]?.['aop'] + ' W'}</strong>
                                    <div className="d-flex align-items-center">
                                        <span>OAP</span>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Body>
                                                        <span className='color-text text-center d-flex'>Orbit Average Power</span>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <sup>
                                                <i className="fe fe-info ms-1 fs-12 text-info"></i>
                                            </sup>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.["userData"]?.['AltMeasureAccuracy']}<sup>o</sup></strong>
                                    <span>Attitude Accuracy</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.["userData"]?.['dutyCycle']} %</strong>
                                    <span>Pass Duty Cycle</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{v?.['atmosData']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                    <span>Peak Power</span>
                                </div>

                                {v?.['atmosData']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'] && v?.['atmosData']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length > 0 &&
                                    <div className="d-flex flex-column mt-3">
                                        <div className="d-flex gap-1 w-100 flex-wrap">
                                            {v?.['atmosData']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].map((val, i) => {
                                                return (
                                                    <div key={i} className="d-flex gap-1">
                                                        <strong>{val}</strong>
                                                        <strong>{`${v?.['atmosData']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length !== i + 1 ? '|' : ''}`}</strong>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <span>Frequency Bands</span>
                                    </div>}

                            </div>
                        </div>
                        <div className="common-card-footer">
                            <span className="footer-title">Interface</span>
                            <div className="d-flex w-100 flex-wrap">
                                {v?.['atmosData']?.['component_specific_attributes']?.['interfaces'].map((data, i) => {
                                    if (data !== 'GPIO') {
                                        return (
                                            <div className={v?.['atmosData']?.['component_specific_attributes']?.['interfaces'].length > 0 && v?.['userData']?.['selected_interfaces'].includes(data) ? "badge-pill-active mb-2" : 'badge-pill-inactive mb-2'} key={i}>
                                                <span>{data}</span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={v?.['userData']?.['selected_interfaces'].includes('GPIO') ? "badge-pill-active ms-1 d-flex mb-2" : "badge-pill-inactive ms-1 mb-2 d-flex"}>
                                                <span>GPIO</span>
                                                {v?.['userData']?.['selected_interfaces'].includes('GPIO') && <span style={{ marginLeft: '2px' }}>{`(${v?.['userData']?.['gpio_count']})`}</span>}
                                            </div>
                                        )
                                    }

                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
            {satPayloads?.length < 4 &&
                <div className="common-card-box-empty d-flex justify-content-center"
                    style={{ width: '440px' }}
                    onClick={() => {
                        if (isPayloadSlider) {
                            dispatch(setPayloadSliderFalse());
                        }
                        navigateTo(`${mission_name}/${mission_id}/${sat_id}/add_new_payload`, navigate)
                    }}
                >
                    {satPayloads?.length > 0 && <div className="d-flex flex-column text-center gap-3">
                        <i className="fe fe-plus fs-50 fw-light"></i>
                        <span className='fs-16'>Add Payload</span>
                    </div>}
                    {satPayloads?.length === 0 && <div className="d-flex flex-column text-center gap-3">
                        <i className="fe fe-plus fs-50 fw-light"></i>
                        <div className='d-flex flex-column'>
                            <span className="fs-18">No payload selected yet</span>
                            <span className='fs-16'>Click here to configure a new Payload</span>
                        </div>
                    </div>}
                </div>
            }
        </div>
        <div className='h-100p'>
            <div className="navigator-btn gap-3">
                <CloseButton />
                <Button
                    variant="primary"
                    type="button"
                    className="d-flex align-items-center btn btn-primary pt-1 pb-1 pe-4 ps-5 fs-14"
                    onClick={() => {
                        if (satPayloads?.length > 0 && satPayloads?.length <= 4) {
                            if (isPayloadDelete) {
                                setIsPayloadDelete(false)
                            }
                            if (satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge']?.length > 0) {
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
                            } else {
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
                            }
                        } else if (satPayloads?.length > 4) {
                            toast.error("You can't add more than 4 payloads", { toastId: 'o' })
                        } else {
                            toast.error("Add atleast one payload to proceed", { toastId: 'o' })
                        }
                    }}
                >
                    {isPayloadDelete ? 'Save & Continue' : 'Next'}
                    <i className="fe fe-chevron-right ms-1 fs-16"></i>
                </Button>
            </div>
            <div className="stepper-container">
                <div className="breadcrumb">
                    {Steps?.map((data, i) => {
                        return <a
                            onClick={() => { navigate_to_step(data.title) }}
                            className={data.title === 'Payloads' ? 'active' : ''}
                            key={i}>
                            <span className={"breadcrumb__inner"}>
                                <span className="breadcrumb__title">
                                    {data.svg}
                                </span>
                                <span className="breadcrumb__desc">{data.title}</span>
                            </span>
                        </a>
                    })}
                </div>
            </div>
        </div>


        {isPayloadSlider && (
            <PayloadSlider
                payloadSliderData={payloadSliderData}
            />
        )}
        <Dialog
            onClick={(event) => {
                if (event) {
                    deletePreviewPayloads(deletedPayloadData)
                }
                setOpenDeleteModal(false)
                setDeletedPayloadData({})
            }}
            openModel={openDeleteModel} comment='Are you sure you want to delete this payload ?'
        />
    </div>;

};
export default PreviewPayloads;
