import React, { useContext } from 'react'
import { ModalContext } from '../providers/ModalProvider';

const useModals = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useScenario must be used within a ScenarioProvider');
    }
    return context;
}

export default useModals