import { useEffect, useMemo, useState } from "react";

const useUserData = () => {
    const [user_data, set_user_data] = useState<any>(null);
    const use_additional_orbit = useMemo(() => user_data?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? true : false, [user_data]);

    useEffect(() => {
        if (sessionStorage.getItem("userDetails")) {
            let user = JSON.parse(sessionStorage.getItem("userDetails") || "");
            set_user_data(user);
        }
    }, [])

    return {
        user_data,
        use_additional_orbit
    }
}

export default useUserData