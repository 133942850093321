import React, { createContext, useState, ReactNode, useEffect, useMemo } from 'react';
import * as Cesium from "cesium";
import useScenario from '../hooks/useScenario';
import useTimeline from '../hooks/useTimeline';
import useTrueTwin from '../hooks/useTrueTwin';
import useUrlParams from '../hooks/useUrlParams';

interface ViewerContextType {
    viewer: Cesium.Viewer | null;
    setViewer: (data: any) => void;
    current_simulation_time: number;
    set_current_simulation_time: (data: number) => void;
}

export const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

interface CesiumProviderProps {
    children: ReactNode;
}

const CesiumProvider = ({ children }: CesiumProviderProps) => {
    const { set_simulation_time, simulation_time, scenario } = useScenario()
    const { dashboard } = useUrlParams()
    // const { get_truetwin_status, TrueTwin } = useTrueTwin()
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null)
    const [current_simulation_time, set_current_simulation_time] = useState<number>(0)

    const { window_width, timeframe_details, set_cesium_time, timeline_container } = useTimeline()
    const { frame_start_time, frame_duration } = timeframe_details

    // const has_tt_scenario = useMemo(() => TrueTwin && scenario, [TrueTwin, scenario])
    const has_tt_scenario = useMemo(() => scenario, [scenario])

    useEffect(() => {
        if (has_tt_scenario) {
            const simulation_duration = simulation_time.duration
            const simulation_end_time = simulation_time.end_time
            const simulation_end_time_milisec = simulation_end_time * 1000

            if (simulation_end_time && simulation_duration && timeline_container.slots.length > 0) {
                if (dashboard !== 'operate') {
                    const Interval = setInterval(() => {
                        const clock = viewer?.clock;
                        if (clock) {
                            const currentTime = clock?.currentTime;
                            if (currentTime) {
                                const epochTime = Cesium.JulianDate.toDate(currentTime).getTime();
                                set_cesium_time(viewer, epochTime)
                                if (epochTime >= simulation_end_time_milisec && dashboard !== "summary") {
                                    clearInterval(Interval);
                                    set_current_simulation_time(simulation_end_time_milisec)
                                    // get_truetwin_status()
                                } else {
                                    set_current_simulation_time(epochTime)
                                }
                            }
                        }
                        // Code to run every second goes here
                    }, 1000);
                    return () => {
                        clearInterval(Interval);
                    };
                } else if (dashboard === 'operate') {
                    let current_time = new Date().getTime()
                    const Interval = setInterval(() => {
                        current_time = new Date().getTime()
                        if (current_time >= simulation_end_time_milisec) {
                            const new_simulation_end_time = simulation_end_time + simulation_duration
                            set_cesium_time(viewer, current_time)
                            set_simulation_time({
                                start_time: simulation_end_time,
                                end_time: new_simulation_end_time,
                                duration: simulation_duration
                            })
                            set_current_simulation_time(current_time)
                        } else {
                            set_cesium_time(viewer, current_time)
                            set_current_simulation_time(current_time)
                        }
                        // Code to run every second goes here
                    }, 1000);
                    return () => {
                        clearInterval(Interval);
                    };
                }
            }
        }
    }, [has_tt_scenario, simulation_time, window_width, frame_duration, frame_start_time, timeline_container]);

    return (
        <ViewerContext.Provider value={{ viewer, setViewer, current_simulation_time, set_current_simulation_time }}>
            {children}
        </ViewerContext.Provider>
    )
}

export default CesiumProvider