import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../CommonComponents/CommonFunctions"
import useUrlParams from "./useUrlParams"

const useUrlNavigation = () => {

    const navigate = useNavigate();
    const { mission_name, mission_id, sat_id, dashboard, scenario_id, tab_name, sat_grp_id } = useUrlParams()

    const navigate_to = (tab: string, truetwinId: string) => {
        const search_params = window.location.search ? `${window.location.search}` : ""
        navigateTo(`${mission_name}/${mission_id}/${dashboard}/${scenario_id}/satellite/${sat_id}/truetwin/${truetwinId}/satGroupId/${sat_grp_id}/${tab}${search_params}`, navigate)
    }

    const update_command_center_url = (satelliteId: string, truetwinId: string) => {
        const search_params = window.location.search ? `${window.location.search}` : ""
        // navigateTo(`${mission_name}/${mission_id}/${dashboard}/${scenario_id}/satellite/${satelliteId}/truetwin/${truetwinId}/${tab_name}${search_params}`, navigate)
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/simulation/${scenario_id}/satellite/${satelliteId}/truetwin/${truetwinId}/satGroupId/${sat_grp_id}/${tab_name}`)
    }

    const navigate_to_simulate = () => {
        navigateTo(
            `${mission_name}/${mission_id}/simulate`,
            navigate
        );
    }
    const navigate_to_operate_dashboard = () => {
        navigateTo(
            `${mission_name}/${mission_id}/operate-dashboard`,
            navigate
        );
    }

    return { navigate_to, navigate_to_simulate, navigate_to_operate_dashboard, update_command_center_url }
}
export default useUrlNavigation