

import { useEffect, useState } from "react";
import './Telemetry.scss'
import { CollapseSvg, PinSvg, TelemetrySvg } from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import { useSearchParams } from "react-router-dom";
import CustomSelect, { Option } from "../../../../custom_components/CustomSelect";
import { Monitor_Btn_List } from "../../monitor/utils";
import useMonitor from "../../../../hooks/useMonitor";
import EPS from "../../../grafana_panels/EPS";
import OBC from "../../../grafana_panels/OBC";
import ADCS from "../../../grafana_panels/ADCS";
import Communication from "../../../grafana_panels/Communication";
import Payload from "../../../grafana_panels/Payload";
import useTrueTwin from "../../../../hooks/useTrueTwin";

const Telemetry = () => {
    const [searchParams] = useSearchParams();
    const [modal, setModal] = useState({
        open: false,
        pinned: false,
        current_modal: ''
    })

    const [expand, setExpand] = useState(false);
    // const { remoteAppConnected } = useTrueTwin()
    const { handleUpdateUrl, getModalDetails } = useModals()
    const { panels, selected_panel, change_selected_panel } = useMonitor()

    useEffect(() => {
        const details = getModalDetails('telemetry')
        setModal(details)
    }, [searchParams])

    const open_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('telemetry', 'pinned')
        } else {
            handleUpdateUrl('telemetry', 'open')
        }
    }

    const pinned_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('telemetry', 'open')
        } else {
            handleUpdateUrl('telemetry', 'pinned')
        }
    }
    const close_modal = (event: any) => {
        event.stopPropagation()
        handleUpdateUrl('telemetry', 'close')
    }

    useEffect(() => {
        const close_modal = () => {
            const details = getModalDetails('telemetry')
            if (details.open && !details.pinned) {
                handleUpdateUrl('telemetry', 'close')
            }
        }
        window.addEventListener('click', close_modal)
        return () => {
            window.removeEventListener('click', close_modal)
        }
    }, [])

    return (
        <Modal
            open={modal.open}
            pinned={modal.current_modal === 'telemetry' && modal.pinned}
            disabled={modal.current_modal === 'telemetry' && modal.pinned}
            class_name={`${modal.current_modal === 'telemetry' ? 'current__modal' : ''}`}
        >
            <div className={`telemetry__panel__container ${expand && 'expand'} `} onClick={open_modal}>
                <div className="telemetry__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <TelemetrySvg />
                        <span className="panel__header__title">Telemetry</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`} onClick={pinned_modal}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`} onClick={close_modal}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="telemetry__panel__body__container" >
                    <div className="telemetry__panel__body__container__header">
                        <CustomSelect
                            className="header__select__container"
                            options={Monitor_Btn_List}
                            disabled_search={true}
                            // hide_option={!remoteAppConnected ? 'Payload' : false}
                            // disabled_option={!remoteAppConnected ? 'Payload' : false}

                            hide_option={'Payload'}
                            disabled_option={'Payload'}
                            value={{ label: selected_panel, value: selected_panel }}
                            onChange={(data) => { change_selected_panel(data.label) }}
                        />
                    </div>
                    <div className="telemetry__panel__body__container__body">
                        {selected_panel === 'EPS' && <EPS monitor_view_type={'List'} panels={panels ? panels : []} />}
                        {selected_panel === 'OBC' && <OBC monitor_view_type={'List'} panels={panels ? panels : []} />}
                        {selected_panel === 'ADCS' && <ADCS monitor_view_type={'List'} panels={panels ? panels : {}} />}
                        {selected_panel === 'Communication' && <Communication monitor_view_type={'List'} panels={panels ? panels : {}} />}
                        {selected_panel === 'Payload' && <Payload monitor_view_type={'List'} panels={panels ? panels : {}} />}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Telemetry