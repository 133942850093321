import { Form } from 'react-bootstrap'
import { CloseSvg, ScenarioLogo } from '../../svgs/Svgs'
import useScenario from '../../hooks/useScenario'
import { containsSpecialChars } from '../../../../CommonComponents/CommonFunctions'
import { toast } from 'react-toastify'
import CustomToolTip from '../../../../CommonComponents/CustomToolTip'
import { useMemo, useState } from 'react'
import useModals from '../../hooks/useModals'
import { maximise_object_modal, minimise_object_modal, orbit_field_names, validate_orbit_details } from '../../utils'
import { AdditionalOrbitFormType, SatelliteOrbitType } from '../../types'


const CreateObject = () => {

    const { additional_orbit_details, set_form_data } = useScenario()
    const { open_additional_orbit_modal, set_open_additional_orbit_modal } = useModals()

    const open = useMemo(() => open_additional_orbit_modal, [open_additional_orbit_modal])

    const close = () => {
        set_open_additional_orbit_modal(false)
        maximise_object_modal()
    }

    const set_orbit_details = (field: string, value: string | number) => {
        const new_orbit_details: AdditionalOrbitFormType = {
            name: additional_orbit_details ? additional_orbit_details['name'] : '',
            altitude: additional_orbit_details ? additional_orbit_details['altitude'] : '',
            eccentricity: additional_orbit_details ? additional_orbit_details['eccentricity'] : '',
            inclination: additional_orbit_details ? additional_orbit_details['inclination'] : '',
            RAAN: additional_orbit_details ? additional_orbit_details['RAAN'] : '',
            AP: additional_orbit_details ? additional_orbit_details['AP'] : '',
            TA: additional_orbit_details ? additional_orbit_details['TA'] : '',
        };
        new_orbit_details[field] = value;
        set_form_data('additional_orbits_details', new_orbit_details)
    }

    const update_satellite_orbit_details = () => {
        const orbit_name = additional_orbit_details ? additional_orbit_details['name'] : ''
        const orbit_details: SatelliteOrbitType = {
            altitude: additional_orbit_details ? additional_orbit_details['altitude'] : '',
            eccentricity: additional_orbit_details ? additional_orbit_details['eccentricity'] : '',
            inclination: additional_orbit_details ? additional_orbit_details['inclination'] : '',
            RAAN: additional_orbit_details ? additional_orbit_details['RAAN'] : '',
            AP: additional_orbit_details ? additional_orbit_details['AP'] : '',
            TA: additional_orbit_details ? additional_orbit_details['TA'] : '',
        }
        if (!additional_orbit_details) {
            return toast.error('Please enter orbit details.', { toastId: 'create-object-error' })
        }
        else if (!orbit_name) return toast.error('Please enter object name.', { toastId: 'create-object-error' })
        else if (!validate_orbit_details(orbit_details)) return
        else {
            // call api to add additional orbit details
            close()
        }
    }

    return (
        <div className={`create__additional__object__container ${open ? 'open' : ''}`}>
            <div className='create__additional__object__header'>
                <div className='d-flex gap-2 align-items-center'>
                    <ScenarioLogo />
                    <span className='header__title'>Additional Orbital Details</span>
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <CustomToolTip title='Minimize' placement='top'>
                        <button className='header__button' onClick={minimise_object_modal}>
                            <i className='fe fe-minus' />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='Close' placement='top'>
                        <button className='header__button' onClick={close}>
                            <CloseSvg />
                        </button>
                    </CustomToolTip>
                </div>
            </div>
            <div className='create__additional__object__body'>
                <Form className='dropdown__container active p-3 gap-2'>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>Object Name *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details?.name}
                                onChange={(event) => {
                                    let isNotValidName = containsSpecialChars(event.target.value)
                                    if (isNotValidName) {
                                        toast.error('Object name cannot contain special characters.', { toastId: 'o' })
                                    } else {
                                        if (event.target.value.length > 100) {
                                            toast.error('Object name cannot be greater than 100 characters. ', { toastId: 'o' })
                                        } else {
                                            set_orbit_details('name', event.target.value.replace(/\s{2,}/g, " ").trimStart());
                                        }
                                    }
                                }}
                                className="orbit__text__input w-100 border-0"
                                type="text"
                                placeholder="name"
                            />
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>Altitude (km) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.altitude : ''}
                                min={orbit_field_names['altitude'].min}
                                max={orbit_field_names['altitude'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number <= 750 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('altitude', value);
                                        }
                                    }
                                }}
                                className="orbit__text__input"
                                type="number"
                                step='any'
                                placeholder="altitude"
                            />
                            <div className='parameter__section'>km</div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>Eccentricity (e) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.eccentricity : ''}
                                min={orbit_field_names['eccentricity'].min}
                                max={orbit_field_names['eccentricity'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number >= 0 && value as number <= 0.05 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('eccentricity', value);
                                        }
                                    } else {
                                        toast.error("Eccentricity must be between 0 and 0.05", { toastId: '0' })
                                    }
                                }}
                                className="orbit__text__input border-0 w-100"
                                type="number"
                                step='any'
                                placeholder="eccentricity"
                            />
                        </div>
                    </div>


                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>Inclination (i) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.inclination : ''}
                                min={orbit_field_names['inclination'].min}
                                max={orbit_field_names['inclination'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number >= 0 && value as number <= 180 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('inclination', value)
                                        }
                                    } else {
                                        toast.error("Inclination must be between 0 and 180", { toastId: '0' })
                                    }
                                }}
                                className="orbit__text__input"
                                type="number"
                                step='any'
                                placeholder="inclination"
                            />
                            <div className='parameter__section'><sup>o </sup></div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>RAAN (Ω) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.RAAN : ''}
                                min={orbit_field_names['RAAN'].min}
                                max={orbit_field_names['RAAN'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('RAAN', value)
                                        }
                                    } else {
                                        toast.error("Longitude of Ascending Node must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="orbit__text__input"
                                type="number"
                                step='any'
                                placeholder="degree"
                            />
                            <div className='parameter__section'><sup>o </sup></div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>Argument of periapsis (ω) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.AP : ''}
                                min={orbit_field_names['AP'].min}
                                max={orbit_field_names['AP'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('AP', value)
                                        }
                                    } else {
                                        toast.error("Argument of periapsis must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="orbit__text__input"
                                type="number"
                                step='any'
                                placeholder="degree"
                            />
                            <div className='parameter__section'><sup>o </sup></div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span className='opacity-60 fs-12'>True Anomaly (&#957;) *</span>
                        <div className='orbit__text__input__container w-40'>
                            <Form.Control
                                value={additional_orbit_details ? additional_orbit_details.TA : ''}
                                min={orbit_field_names['TA'].min}
                                max={orbit_field_names['TA'].max}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                        if (event.target.value?.length <= 8) {
                                            set_orbit_details('TA', value)
                                        }
                                    } else {
                                        toast.error("True Anomaly must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="orbit__text__input"
                                type="number"
                                step='any'
                                placeholder="degree"
                            />
                            <div className='parameter__section'><sup>o </sup></div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className='create__additional__object__footer'>
                <button className='footer__close__button' onClick={close}>Close</button>
                <button className='footer__next__button'
                    onClick={update_satellite_orbit_details}
                >Next</button>
            </div>
        </div>
    )
}

export default CreateObject