
import { toast } from 'react-toastify'
import useScenario from '../../../hooks/useScenario'
import useUrlParams from '../../../hooks/useUrlParams'
import StepHeader from './StepHeader'
import { containsSpecialChars } from '../../../../../CommonComponents/CommonFunctions'
import { Form } from 'react-bootstrap'
import { useMemo } from 'react'

const Details = () => {
    const { scenario_step, navigate_scenario_step } = useUrlParams()
    const { loading, scenario_name, scenario_response, set_form_data } = useScenario()

    const active = useMemo(() => scenario_step === 'details' ? 'active' : '', [scenario_step])
    const completed = useMemo(() => scenario_response && scenario_response?.['scenarioName'] ? true : false, [scenario_response])
    const disabled = useMemo(() => (loading || (!completed && !active)) ? 'disabled' : '', [loading, completed, active])


    return (
        <section id='details' className={`dropdown__container ${active} ${disabled}`}>
            <StepHeader
                step='Scenario Details'
                completed={completed}
                onClick={() => { navigate_scenario_step('details') }}
            />
            <div className='dropdown__container__body'>
                <Form className='w-100 d-flex flex-column gap-2'>
                    <div className='w-100 d-flex flex-column gap-1'>
                        <span className='opacity-75 fs-14'>Scenario Title</span>
                        <div className='orbit__text__input__container'>
                            <Form.Control
                                value={scenario_name}
                                onChange={(e) => {
                                    if (containsSpecialChars(e.target.value)) {
                                        toast.error("Special characters are not allowed", { toastId: "error" })
                                    } else if (e.target.value?.length <= 100) {
                                        set_form_data('name', e.target.value.replace(/\s{2,}/g, " ").trimStart())
                                    } else {
                                        toast.error("Scenario title can't have more than 100 characters", { toastId: "err" })
                                    }
                                }}
                                className="orbit__text__input"
                                type="text"
                                placeholder="Scenario title"
                                required
                            />
                        </div>
                    </div>
                    <div className='w-100 d-flex flex-column gap-1 opacity-50'>
                        <span className="fs-14">Time Scaling Factor</span>
                        <div className='orbit__text__input__container'>
                            <Form.Control
                                disabled
                                value={'Real Time'}
                                className="orbit__text__input"
                                type="text"
                                placeholder="Time scaling Factor"
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </section>
    )
}

export default Details