import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";
// import { BASE_APP_URL } from "./ApiService";

const getProjects = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project`);
};

const getProjectbyId = (projectId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/${projectId}`);
};

const createMission = (payload, projectId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission`, payload);
}

const updateMission = (missionId, payload) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}`, payload);
}
const updateMissionStations = (missionId, payload) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}missions/patch/${missionId}`, payload);
}

const get_mission_by_id = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}`);
}
const startMissionBuild = (mission, buildName, buildType) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}build?missionId=${mission}&buildLabel=${buildName}&buildType=${buildType}`);
}

const gettMissionBuild = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}build`);
}
const gettMissionId = (tenant_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission?tenantId=${tenant_id}`);
}
const deleteMission = (missionId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}`);
}

const getMissionPayload = (selectedTemplate, nameOfMission, isDraft, pageNumber) => {
  let payload = {
    "name": nameOfMission,
    "description": "",
    "mission-attributes": selectedTemplate['mission-attributes'],
    "sbpName": nameOfMission,
    "sbpDescription": "",
    "sbpBuildInfo": selectedTemplate.sbps[0].build_info,
    "sbpAssembly": selectedTemplate.sbps[0].versions[0].assembly
  }
  if (isDraft) {
    payload["mission-attributes"].status = "draft";
    payload["mission-attributes"].pageNumber = pageNumber;
  } else {
    payload["mission-attributes"].status = "configured";
  }

  return payload;
}

const getMissionSatInfo = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroupDetails`)
}

const deployBuild = (buildId, missionId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}build/mission?buildId=${buildId}&missionId=${missionId}`, {});
}

const getDeployStatus = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}build/mission?missionId=${missionId}`, {});
}

const startTest = (missionId, testCaseId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test-run?missionId=${missionId}&testCaseId=${testCaseId}`, {});
}


const getTestRuns = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test-run?missionId=${missionId}`);
}

const getTestCases = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test-case`);
}

const downloadTrueTwinPayloadConfig = (trueTwinId, fileId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}/download?fileId=${fileId}`);
}
const downloadTrueTwinADCSConfig = (trueTwinId, fileName) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}/downloadfile?filename=${fileName}`);
}

const create_new_satellites = (data, mission_id) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/satelliteGroup`, data);
}

const get_satellites_by_mission_id = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup`,);
}
const get_satellite_by_sat_id = (satId, mission_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/satelliteGroup/${satId}`,);
}

const get_payload_by_sat_id = (satId, missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload`,);
}

const get_payload_by_payload_id = (satId, payload_id, missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload/${payload_id}`,);
}

const get_edge_by_sat_id = (satId, missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/edge`,);
}



const update_satellites = (satId, data) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/satelliteGroup/${satId}/`, data);
}

const add_sequence = (data, satId, missionId, payloadId) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload/${payloadId}/sequence`, data);
}

const update_sequence = (satId, missionId, payloadId, sequenceId, data) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload/${payloadId}/sequence/${sequenceId}`, data);
}

const delete_sequence = (satId, missionId, payloadId, sequenceId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload/${payloadId}/sequence/${sequenceId}`,);
}

const add_edge = (satId, missionId, data) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/edge`, data);
}

const add_payload = (satId, missionId, data) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/payload`, data);
}

const add_bus = (satId, missionId, templateId) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}/bus?templateId=${templateId}`);
}

const clone_satellite = (satId, satName) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/clone?satId=${satId}&satName=${satName}`,);
}

const delete_satellite = (satId, missionId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup/${satId}`,);
}

const post_service_obeject = (satId, body) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}`, body);
}

const getTasks = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${satId}/task`,);
};

const deleteTask = (task_id) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task?taskId=${task_id}`,);
}
const postTaskPlan = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}taskplan`, body);
}
const getTaskPlan_by_id = (taskPlanId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}taskplan?taskPlanId=${taskPlanId}`);
};
const getAllTaskPlan = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${satId}/taskplan`);
};

const deleteTaskPlanById = (taskPlanId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}taskplan?taskPlanId=${taskPlanId}`,)
}

const updateTaskPLanById = (taskPlanId, data) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}taskplan?taskPlanId=${taskPlanId}`, data)
}
const postSchedule = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}schedule`, body);
}

const putSchedule = (schedule_id, body) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}schedule?scheduleId=${schedule_id}`, body);
}

const getMissions = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${satId}/schedule`,);
};

const getGroundLinkList = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}/groundlink?=${satId}`,);
};

const createGroundLink = (satId, data) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}/groundlink?=${satId}`, data);
};

const deleteGroundLink = (satId, name) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}/groundlink?name=${name}`,);
};


const getGroundStations = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/groundstations?scenarioId=${scenarioId}`);
}


const cloneScenario = (id, name, useExisting) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/clone?scenarioId=${id}&scenarioName=${name}&useExisting=${useExisting}`,);
}

const postScenario = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario`, body);
}

const putScenario = (scenarioId, body) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario?scenarioId=${scenarioId}`, body);
}
const patchScenario = (scenarioId, body) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario?scenarioId=${scenarioId}`, body);
}
const addExperimentalObjects = (scenarioId, body) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/attachobits?scenarioId=${scenarioId}`, body);
}

const getScenarioById = (id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario?scenarioId=${id}`);
}

const getAllScenarios = (satId, ttId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/all?satId=${satId}&truetwinId=${ttId}`)
}

const deleteScenario = (scenarioId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario?scenarioId=${scenarioId}`)
}
const runSimulation = (data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/simulation`, data)
}

const getOribtData = (scenarioId, trueTwinId, propagation) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/orbit?scenarioId=${scenarioId}&trueTwinId=${trueTwinId}&propagation=${propagation}`);
}

const getSimulationStatus = (simulationId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/simulation/status?simulationId=${simulationId}`);
}

const generateSchedule = (scenarioId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/generateschedule?scenarioId=${scenarioId}`);
}

const downloadSchedule = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/exportschedule?scenarioId=${scenarioId}`);
}

const createValidationTest = (data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test`, data)
}
const updateValidationTest = (validationId, data) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test?validationTestId=${validationId}`, data)
}
const deleteValidationTest = (validationId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test?validationTestId=${validationId}`)
}

const getAllValidationTests = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/all?satId=${satId}`)
}

const getValidationTest = (validationTestId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test?validationTestId=${validationTestId}`)
}

const runValidationTest = (body, validationTestId, flatSatId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/run?validationTestId=${validationTestId}&flatSatId=${flatSatId}`, body)
}

const getAllValidationRunsTest = (satId, flatSatId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/run?satId=${satId}&flatSatId=${flatSatId}`)
}

const getStatusOfValidationRunsTest = (validationRunTestId, senarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/runStatus?validationRunTestId=${validationRunTestId}&scenarioId=${senarioId}`)
}

const deleteValidationRunTest = (validationRunTestId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/run?validationRunTestId=${validationRunTestId}`)
}

const stopValidationRunTest = (validationRunTestId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test/stop?validationRunTestId=${validationRunTestId}`)
}

const getTestPlanbyId = (testPlanId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}validation-test?validationTestId=${testPlanId}`);
}
const uploadFile = (ttId, satId, name, comment, category, taskPlanId, file) => {
  return ApiService.upload(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/uploadLogFile?truetwinId=${ttId}&satId=${satId}&comment=${comment}&category=${category}&name=${name}&taskPlanId=${taskPlanId}`, file);
}
const getTaskFromTaskType = (taskType) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task/getTaskByType?taskType=${taskType}`);
}
const getAllTask = (satId, mission_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task/${satId}/all`);
}

const createGsProvider = (missionId, data) => {
  return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/gsConfig`, data)
}

const putSatelliteConfig = (satId) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/defaultConfig?satId=${satId}`)
}

const postSatelliteConfig = (body, satId) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/configuration/${satId}`, body)
}

const getSatelliteConfigData = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/configuration/${satId}`);
}

const getCommsCount = (ttId, scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/telemetry/counts?truetwinId=${ttId}&scenarioId=${scenarioId}`);
}

const getControlMode = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/ADCSControlModeLogs?scenarioId=${scenarioId}`);
}

const getEstimationMode = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/ADCSControlEstModeLogs?scenarioId=${scenarioId}`);
}

const postScenarioSchedule = (scenarioId, body) => {
  return ApiService.upload(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/importschedulefile?scenarioId=${scenarioId}`, body)
}

const postSimulationGraph = (type, scenarioId, data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/graphs/${type}?scenarioid=${scenarioId}`, data)
}

const deleteAdditionalObject = (scenarioId, orbitId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/attachobits?scenarioId=${scenarioId}&orbitId=${orbitId}`)
}

const getPredictionStatus = (scenarioId) => {
  return ApiService.prediction(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/prediction?scenarioId=${scenarioId}`)
}

const postThreedModel = (satID, body) => {
  return ApiService.upload(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satID}/model`, body)
}

const getModelUrl = (satId, modelName) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/model/${satId}/${modelName}`)
}

const postFeature = (mission_id, data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/areaOfInterests`, data)
}

const getAoiManagersByMissionId = (mission_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/areaOfInterestManager`);
}

const getAllAoisByManagerId = (areaOfInterestManagerId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterests/${areaOfInterestManagerId}`);
}

const deleteAoiManagerByManagerId = (areaOfInterestManagerId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterestManager/${areaOfInterestManagerId}`)
}

const deleteAoiById = (AoiId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${AoiId}`)
}

const updateAoiById = (aoiId, data) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${aoiId}`, data)
}

const getAoiById = (aoiId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/areaOfInterest/${aoiId}`);
}

const getAllAoisByType = (mission_id, type) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/areaOfInterests/${type}`);
}
const getAllAoisByMissionId = (mission_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/areaOfInterests`);
}

const getAdhocComponents = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/task/gettaskbycomponentname`)
}

const getTaskById = (taskId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/task?taskId=${taskId}`)
}

const deleteTaskByOperationId = (taskId, operationId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task/${taskId}/operation?operationId=${operationId}`)
}

const getTaskByoperationId = (taskId, operationId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/task/deleteSequenceByOperationId?taskId=${taskId}&operationId=${operationId}`)
}

const putTasks = (task_id, body) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task?taskId=${task_id}`, body);
}

const postTask = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task`, body);
}

const deleteSatelliteBucket = (satId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}/model`)
}

const getMissionModellingModels = (mission_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/model/all?missionid=${mission_id}`)
}

const createMissionModellingModal = (mission_id) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/model?missionid=${mission_id}`)
}
//// Launch Apis ////

const postOrbitDetails = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/tle`, body);
}
const updateOrbitDetails = (satId, tleType, body) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/tle?satId=${satId}&tleType=${tleType}`, body);
}
const getLatestOrbitDetails = (satId, launchType) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/tle?satId=${satId}&tleType=${launchType}`);
}

const get_all_orbit_details = (mission_id, satelliteGroupId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${mission_id}/satelliteGroup/${satelliteGroupId}/satellites/orbitParameters`);
}
const getAllOrbitDetails = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/tle/all?satId=${satId}`);
}
const deleteOrbitDetails = (satId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}project/tle?satId=${satId}`);
}

//// Launch Apis ////


const MissionService = {
  getProjects,
  get_mission_by_id,
  getProjectbyId,
  createMission,
  updateMission,
  updateMissionStations,
  startMissionBuild,
  gettMissionBuild,
  gettMissionId,
  deleteMission,
  getMissionPayload,
  getMissionSatInfo,
  deployBuild,
  getDeployStatus,
  startTest,
  getTestRuns,
  getTestCases,
  downloadTrueTwinPayloadConfig,
  downloadTrueTwinADCSConfig,
  create_new_satellites,
  get_satellites_by_mission_id,
  update_satellites,
  add_sequence,
  add_payload,
  add_bus,
  add_edge,
  clone_satellite,
  delete_satellite,
  get_satellite_by_sat_id,
  get_payload_by_sat_id,
  get_payload_by_payload_id,
  get_edge_by_sat_id,
  post_service_obeject,
  getTasks,
  deleteTask,
  postSchedule,
  getMissions,
  putSchedule,
  delete_sequence,
  update_sequence,
  postTaskPlan,
  getTaskPlan_by_id,
  getAllTaskPlan,
  deleteTaskPlanById,
  updateTaskPLanById,
  getGroundLinkList,
  createGroundLink,
  deleteGroundLink,
  getGroundStations,
  cloneScenario,
  postScenario,
  getScenarioById,
  putScenario,
  patchScenario,
  addExperimentalObjects,
  getAllScenarios,
  generateSchedule,
  downloadSchedule,
  deleteScenario,
  runSimulation,
  getOribtData,
  getSimulationStatus,
  createValidationTest,
  updateValidationTest,
  deleteValidationTest,
  getAllValidationTests,
  getValidationTest,
  runValidationTest,
  getAllValidationRunsTest,
  getStatusOfValidationRunsTest,
  deleteValidationRunTest,
  stopValidationRunTest,
  getTestPlanbyId,
  uploadFile,
  getTaskFromTaskType,
  getAllTask,
  postSatelliteConfig,
  getSatelliteConfigData,
  getCommsCount,
  getControlMode,
  getEstimationMode,
  putSatelliteConfig,
  postScenarioSchedule,
  postSimulationGraph,
  deleteAdditionalObject,
  getPredictionStatus,
  postThreedModel,
  getModelUrl,
  // feature api
  postFeature,
  getAoiManagersByMissionId,
  getAllAoisByManagerId,
  deleteAoiManagerByManagerId,
  deleteAoiById,
  updateAoiById,
  getAoiById,
  getAllAoisByType,
  getAllAoisByMissionId,
  // NewTaskcreationApi //
  putTasks,
  postTask,
  getAdhocComponents,
  getTaskById,
  getTaskByoperationId,
  deleteTaskByOperationId,
  // NewTaskcreationApi //
  deleteSatelliteBucket,
  getMissionModellingModels,
  createMissionModellingModal,

  //// Launch Apis ////
  postOrbitDetails,
  updateOrbitDetails,
  getLatestOrbitDetails,
  get_all_orbit_details,
  getAllOrbitDetails,
  deleteOrbitDetails
  //// Launch Apis ////

};
export default MissionService;
