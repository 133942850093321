import { FC } from 'react'
import { DropdownArrow, StepCompletedSvg } from '../../../svgs/Svgs'

type StepHeaderProps = {
    step: string,
    completed: boolean,
    onClick: () => void
    button?: JSX.Element
}

const StepHeader: FC<StepHeaderProps> = ({ step, completed, onClick, button }) => {
    return (
        <button className='dropdown__container__header' onClick={onClick}>
            <div className='d-flex gap-1 align-items-center'>
                <DropdownArrow />
                <span className='dropdown__header__title'>{step}</span>
            </div>
            <div className='d-flex gap-2 align-items-center'>
                {button}
                <div className={`${completed ? 'd-flex' : 'd-none'}`}>
                    <StepCompletedSvg />
                </div>
            </div>
        </button>
    )
}

export default StepHeader