import { InfoSvg } from "../../svgs/Svgs"
import '../ModalStyle.scss'
import { Form } from 'react-bootstrap'
import { containsSpecialChars } from '../../../CommonComponents/CommonFunctions'
import { toast } from 'react-toastify'
import CustomSelect from '../../../CommonComponents/CustomSelect'
import { FC } from "react"

type Satellite = {
    label: string,
    value: any
}

interface DetailsForm {
    scenario_name: string,
    satellite: Satellite | null,
    all_satellites: Satellite[] | [],
    deployment_type: { label: string, value: string }
    set_scenario_name: (value: string) => void,
    set_satellite: (value: Satellite | null) => void,
    set_deployment_type: (value: { label: string, value: string }) => void,
}

const DeployTypeOptions = [
    {
        label: 'HWIL',
        value: 'HWIL'
    },
    {
        label: 'SWIL',
        value: 'SWIL'
    },
]

const DetailsForm: FC<DetailsForm> = ({ scenario_name, satellite, all_satellites, deployment_type, set_scenario_name, set_satellite, set_deployment_type }) => {

    return (
        <Form className='w-100 d-flex flex-column gap-3'>
            <div className='w-100 d-flex flex-column gap-1'>
                <span className='opacity-75 fs-14'>Scenario Title</span>
                <Form.Control
                    value={scenario_name}
                    onChange={(e) => {
                        if (containsSpecialChars(e.target.value)) {
                            toast.error("Special characters are not allowed", { toastId: "error" })
                        } else if (e.target.value?.length <= 100) {
                            set_scenario_name(e.target.value)
                        } else {
                            toast.error("Scenario title can't have more than 100 characters", { toastId: "err" })
                        }
                    }}
                    className="custom__text__input"
                    type="text"
                    placeholder="Scenario title"
                    required
                />
            </div>
            <div className='info__badge'>
                <InfoSvg />
                <span>On deploying a group, only one instance of <span className='heighlit_text'>satellite profile</span> is deployed. </span>
            </div>
            <div className='w-100 d-flex align-items-center justify-content-between gap-2'>
                <div className='w-100 d-flex flex-column gap-1 flex-1'>
                    <span className="opacity-50 fs-14">Satellite/Group</span>
                    <CustomSelect
                        options={all_satellites}
                        placeholder='Select Satellite/Group'
                        value={satellite}
                        onChange={(e: { label: string, value: any }) => {
                            set_satellite(e)
                        }}
                    />
                </div>
                <div className='w-100 d-flex flex-column gap-1 flex-1'>
                    <span className="opacity-50 fs-14">Deployment Mode</span>
                    <CustomSelect
                        options={DeployTypeOptions}
                        placeholder='Select Deployment Mode'
                        value={deployment_type}
                        onChange={(e: { label: string, value: any }) => {
                            set_deployment_type(e)
                        }}
                    />
                </div>
            </div>
        </Form>
    )
}

export default DetailsForm