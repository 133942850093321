import ScenarioProvider from './providers/ScenarioProvider'
import Visualization from './visualization/Visualization'
import './Scenario.scss'
import ScenarioHeader from './ScenarioHeader'
import CreateScenario from './modals/creation/CreateScenario'
import ViewerProvider from './providers/ViewerProvider'

const Scenario = () => {
    return (
        <ViewerProvider>
            <ScenarioProvider>
                <div className='scenario__container'>
                    <ScenarioHeader />
                    <CreateScenario />
                    <Visualization />
                </div>
            </ScenarioProvider>
        </ViewerProvider>
    )
}

export default Scenario