import './GroundContact.scss'
import { Fragment, useState } from 'react';
import GroundContactSection from './GroundContactSection';
import RightSidePanel from './RightSidePanel';
import ContactModal from './ContactModal';
import CustomSelect from '../../../../custom_components/CustomSelect';
import { time_options } from '../../hud/contact/utils';
import { get_navigator_button_by_dashboard } from '../utils';
import useUrlParams from '../../../../hooks/useUrlParams';
import useUrlNavigation from '../../../../hooks/useUrlNavigation';
import useContacts from '../../../../hooks/useContacts';
import useTimeline from '../../../../hooks/useTimeline';
import useGroundContact from '../../../../hooks/useGroundContact';
import EmptyPage from '../../../../custom_components/EmptyPage';


const GroundContact = () => {
    const { contacts, selectedContact } = useContacts()
    const { tab_name, dashboard, truetwin_id } = useUrlParams()
    const { navigate_to } = useUrlNavigation()
    const { selected_timeframe, select_timeframe } = useTimeline()
    const [showModal, setShowModal] = useState<boolean>(false)
    const { contactData } = useGroundContact()

    return (
        <div className='planning__container__body'>
            {contactData?.length > 0 &&
                <div className='ground__station__contact__container'>
                    <Fragment>
                        <div className='ground__container__left__container__wrapper'>
                            <div className='ground__container__header__container'>
                                <div className='ground__container__header__left__container'>
                                    {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                                        return (
                                            <button className={`button ${tab_name === btn.name && 'active'}`}
                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                    e.stopPropagation()
                                                    navigate_to(btn.name, truetwin_id as string)
                                                }}
                                            >{btn.label}</button>
                                        )
                                    })}
                                </div>
                                <div className='ground__container__header__right__container'>
                                    <div className='w-70'>
                                        <CustomSelect
                                            options={time_options}
                                            value={selected_timeframe}
                                            disabled_search={true}
                                            onChange={(data) => { select_timeframe(data) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <GroundContactSection />
                        </div>
                        <RightSidePanel set_show_modal={() => { setShowModal(true) }} all_contacts={contacts} selected_contact={selectedContact} />
                    </Fragment>
                </div>
            }
            {contactData?.length === 0 &&
                <EmptyPage content='No Ground Contacts' >
                    <div className='ground__container__header__container'>
                        <div className='ground__container__header__left__container'>
                            {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                                return (
                                    <button className={`button ${tab_name === btn.name && 'active'}`}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation()
                                            navigate_to(btn.name, truetwin_id as string)
                                        }}
                                    >{btn.label}</button>
                                )
                            })}
                        </div>
                    </div>
                </EmptyPage>
            }


            {showModal && <ContactModal open={showModal} close={setShowModal} selected_contact={selectedContact} />}
        </div>
    )
}

export default GroundContact