import { useParams, useNavigate } from 'react-router-dom';
import useUserData from './useUserData';

const useUrlParams = () => {
    const params = useParams();
    const navigate = useNavigate()
    const { mission_name, mission_id, scenario_id, scenario_step } = params
    const { use_additional_orbit } = useUserData()

    const close_scenario = () => {
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/simulate`)
    }

    const scroll_to_section = (section: string) => {
        const element = document.getElementById(section)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const navigate_scenario_step = (step: string | 'forword' | 'backword') => {
        if (!scenario_step) return
        if (step === 'forword') {
            switch (scenario_step) {
                case 'details':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/satellite`)
                    break;
                case 'satellite':
                    if (use_additional_orbit) {
                        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/additional_object`)
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/ground_station`)
                    }
                    break;
                case 'additional_object':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/ground_station`)
                    break;
                case 'ground_station':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/schedule`)
                    break;
                case 'schedule':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/deploy_scenario`)
                    break;
                default:
                    break;
            }
            scroll_to_section(scenario_step)
        } else if (step === 'backword') {
            switch (scenario_step) {
                case 'deploy':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/ground_station`)
                    break;
                case 'schedule':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/ground_station`)
                    break;
                case 'ground_station':
                    if (use_additional_orbit) {
                        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/satellite`)
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/additional_object`)
                    }
                    break;
                case 'additional_object':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/satellite`)
                    break;
                case 'satellite':
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/details`)
                    break;
                default:
                    break;
            }
            scroll_to_section(scenario_step)
        } else {
            if (step === 'details' && !scenario_id) {
                navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/details`)
                scroll_to_section('details')
            } else {
                navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario_id}/${step}`)
            }
            scroll_to_section(step)
        }

    }

    return {
        mission_name,
        mission_id,
        scenario_id,
        scenario_step,
        close_scenario,
        navigate_scenario_step
    }
}

export default useUrlParams