
import React, { useEffect, useState } from 'react';
import DraggableContainer from '../../../../draggable_and_resizable/DraggableContainer';

interface DraggableBoxProps {
  modalRef: React.MutableRefObject<boolean>;
  children: any;
  modal: string;
  expanded: boolean | string;
  className: any;
  setDraggResize: (boolean) => void;
}


const DraggableBox = ({ children, modal, expanded, className, modalRef, setDraggResize }: DraggableBoxProps) => {

  const [timeLineData, setTimeLineData] = useState<any>({
    top: 70,
    left: window.innerWidth - 720,
    width: 700,
    height: window.innerHeight - 300,
  })

  useEffect(() => {
    setTimeLineData({
      top: 70,
      left: expanded ? 20 : window.innerWidth - 720,
      width: expanded ? window.innerWidth - 35 : 700,
      height: expanded ? window.innerHeight - 90 : window.innerHeight - 300,
    })
  }, [expanded])

  const handleDrag = (deltaX, deltaY) => {
    if ((timeLineData.top + deltaY > 0) && (timeLineData.left + deltaX > 0)
      && ((timeLineData.top + deltaY + timeLineData.height) < window.innerHeight && (timeLineData.left + deltaX + timeLineData.width) < window.innerWidth)
    ) {
      let left_position = timeLineData.left + deltaX
      let top_position = timeLineData.top + deltaY
      setTimeLineData({
        ...timeLineData,
        left: left_position,
        top: top_position,
      })
    }
  }


  const handleResize = (style: { top: number; left: number; width: number; height: number }) => {
    if (expanded) return
    const { top, left, width, height } = style
    if ((top > 0) && (left > 0)
      && ((top + height) < window.innerHeight && (left + width) < window.innerWidth)
    ) {
      const width_range = modal === 'schedule' ? [600, 1000] : [600, window.innerWidth - 35]
      const height_range = [40, window.innerHeight - 90]
      if ((width >= width_range[0] && width <= width_range[1]) && (height >= height_range[0] && height <= height_range[1])) {
        setTimeLineData({
          top,
          left,
          width,
          height,
        })
      }
    }
  }

  const handleDragStart = () => {
    modalRef.current = true
    setDraggResize(true)
    document.body.style.userSelect = 'none';
  };

  const handleDragEnd = () => {
    setDraggResize(false)
    document.body.style.userSelect = 'auto';
  };

  const handleResizeStart = () => {
    modalRef.current = true
    setDraggResize(true)
    document.body.style.userSelect = 'none';
  };

  const handleResizeEnd = () => {
    setDraggResize(false)
    document.body.style.userSelect = 'auto';
  };

  return (
    <DraggableContainer
      className={className}
      left={timeLineData?.left}
      top={timeLineData?.top}
      width={timeLineData?.width}
      height={timeLineData?.height}
      zoomable='n, w, s, e, nw, ne, se, sw'
      rotatable={false}
      onResize={handleResize}
      onDrag={handleDrag}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onResizeStart={handleResizeStart}
      onResizeEnd={handleResizeEnd}
    >{children}</DraggableContainer>
  );
};

export default DraggableBox;
