import { useEffect, useMemo } from 'react'
import useScenario from '../../../hooks/useScenario'
import useUrlParams from '../../../hooks/useUrlParams'
import StepHeader from './StepHeader'
import { get_station_ids } from '../../../utils'
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip'
import { CheckBoxSvg, EyeSvg } from '../../../svgs/Svgs'
import { create_gs_packets, focus_gs, removed_focus_gs, select_unselect_gs } from '../../../visualization/cesium_utils'
import useViewer from '../../../hooks/useViewer'

const GroundStation = () => {

    const { viewer, focused_ground_station, set_focused_ground_station } = useViewer()

    const { scenario_id, scenario_step, navigate_scenario_step } = useUrlParams()
    const { loading, all_ground_stations, ground_stations, scenario_response, set_form_data } = useScenario()

    const active = useMemo(() => scenario_step === 'ground_station' ? 'active' : '', [scenario_step])
    const completed = useMemo(() => scenario_response && scenario_response?.['groundStations']?.length > 0 ? true : false, [scenario_response])
    const disabled = useMemo(() => (loading || (!completed && !active)) ? 'disabled' : '', [loading, completed, active])

    const toogle_station = (station: any) => {
        if (ground_stations.includes(station)) {
            const stations = ground_stations.filter((s: any) => s !== station)
            set_form_data('ground_stations', stations)
            select_unselect_gs(viewer, station, 'un_checked')
        } else {
            const stations = [...ground_stations, station]
            set_form_data('ground_stations', stations)
            select_unselect_gs(viewer, station, 'checked')
        }
    }

    const toggle_focus_gs = (station: string) => {
        if (focused_ground_station === station) {
            removed_focus_gs(viewer, ground_stations)
            set_focused_ground_station(null)
        } else {
            focus_gs(viewer, station, ground_stations)
            set_focused_ground_station(station)
        }
    }

    return (
        <section id='ground_station' className={`dropdown__container ${active} ${disabled}`}>
            <StepHeader
                step='Ground Stations'
                completed={completed}
                onClick={() => { navigate_scenario_step('ground_station') }}
            />
            <div className='dropdown__container__body p-0 gap-2'>
                {all_ground_stations?.map((station: any, index: number) => (
                    <button className={`drop__down__body__item ${station?.['groundStationId'] === focused_ground_station && 'active'}`} key={index}
                        onClick={() => {
                            toggle_focus_gs(station?.['groundStationId'])
                        }}
                    >
                        <div className='d-flex align-items-center gap-2 opacity-75 w-90'>
                            <button className='bg-transparent border-0 p-0' onClick={(event: any) => {
                                event.stopPropagation()
                                toogle_station(station?.['groundStationId'])

                            }}>
                                <CheckBoxSvg checked={ground_stations?.includes(station?.['groundStationId'])} />
                            </button>
                            <span className='fs-12 w-90 text-truncate text-start'>
                                <CustomToolTip title={station?.['groundStationName']} placement='top'>
                                    {station?.['groundStationName']}
                                </CustomToolTip>
                            </span>
                        </div>
                        <EyeSvg />
                    </button>
                ))}
            </div>
        </section>
    )
}

export default GroundStation