import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";

const getALLScenarios = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario`);
}

const createScenario = (missionId, data) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario`, data);
}
const getScenario = (missionId, scenarioId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}`);
}

const updateScenarioWithSatelliteGroups = (missionId, scenarioId, data) => {
    return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/addSatelliteGroups`, data);
}

const updateScenarioWithExternalOrbits = (missionId, scenarioId, data) => {
    return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/external`, data);
}

const updateScenarioWithGroundStations = (missionId, scenarioId, data) => {
    return ApiService.patch(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/addGroundStations`, data);
}

const deleteScenario = (missionId, scenarioId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}`);
}

const generateSchedule = (missionId, scenarioId, path) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/test/${missionId}/scenario/${scenarioId}/generateonboardschedule?${path}`);
}

/////////////////////GET SATELLITE GROUPS/////////////////////
const getSatelliteGroups = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission/${missionId}/satelliteGroup`);
}
/////////////////////GET SATELLITE GROUPS/////////////////////

const deployScenario = (missionId, scenarioId) => {
    // Deploying scenation
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/deployment`);
}

const getAllScenarioInfo = (missionId, scenarioId) => {
    // Getting Al Infor Of Deploying scenario
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/deploymentdetails`);
}

const unDeployScenario = (missionId, scenarioId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/scenario/${scenarioId}/undeployment`);
}

const postGrafanaData = (scenarioId, missionId, satelliteGroupId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/${scenarioId}/grafana?missionId=${missionId}&satelliteGroupId=${satelliteGroupId}`);
}

const getGrafanaData = (scenarioId, missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/${scenarioId}/grafana?missionId=${missionId}`);
}

const SimulateService = {
    getALLScenarios,
    createScenario,
    getScenario,
    deleteScenario,
    generateSchedule,
    updateScenarioWithSatelliteGroups,
    updateScenarioWithGroundStations,

    /////////////////////GET SATELLITE GROUPS/////////////////////
    getSatelliteGroups,
    /////////////////////GET SATELLITE GROUPS/////////////////////
    deployScenario,

    getAllScenarioInfo,
    unDeployScenario,
    postGrafanaData,
    getGrafanaData
}

export default SimulateService;