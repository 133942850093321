import { FC, useEffect, useMemo, useState } from 'react'
import { ModalClose } from "../../svgs/Svgs"
import '../ModalStyle.scss'
import { NAVIGATION_ITEMS } from '../../utils'
import { useParams } from 'react-router-dom'
import DetailsForm from './DetailsForm'
import BusForm from './BusForm'
import SimulateService from '../../../../service/SimulateService'

export type SatelliteOrbit = {
    altitude: number;
    eccentricity: number;
    inclination: number;
    RAAN: number;
    AP: number;
    TA: number
}
export type SelectedSatelliteType = {
    label: string,
    value: {
        groupId: string;
        groupType: string;
        groupName: string;
        planePhasing: number;
        satCount: number;
        orbit_data: SatelliteOrbit;
    }
}
type Props = {
    open: boolean,
    close: () => void,
}

const QuickDeployModal: FC<Props> = ({ open, close }) => {
    const { mission_id } = useParams()
    const [step, set_step] = useState<string>('Details')
    const [scenario_name, set_scenario_name] = useState<string>('')
    const [satellite, set_satellite] = useState<SelectedSatelliteType | null>(null)
    const [deployment_type, set_deployment_type] = useState<{ label: string, value: string }>({
        label: 'HWIL',
        value: 'HWIL'
    })
    const [all_satellites, set_all_satellites] = useState<SelectedSatelliteType[]>([])

    const [bus_type, set_bus_type] = useState<string>('')

    const disabled__navigation = useMemo(() => deployment_type.value === 'SWIL' ? ['Bus', 'Payload'] : [], [deployment_type])

    useEffect(() => {
        if (!mission_id) return
        SimulateService.getSatelliteGroups(mission_id)?.then((res) => {
            if (res.data) {
                let allSatellites: SelectedSatelliteType[] = [];
                res?.["data"]?.forEach((group: any) => {
                    allSatellites.push({
                        label: group?.["name"],
                        value: {
                            groupId: group?.['uniqueId'],
                            groupType: group?.['groupType'],
                            groupName: group?.["name"],
                            satCount: group?.["satCount"],
                            planePhasing: group?.['interPlanePhasing'],
                            orbit_data: group?.['referenceOrbitParameters'],
                        }
                    });
                })
                set_all_satellites(allSatellites)
            }
        }).catch((err) => {
            console.error(err);
        })
    }, [mission_id])

    const set_deployment_step_forword = () => {
        if (deployment_type.value === 'SWIL') {
            // deploy the scenario
        } else {
            switch (step) {
                case 'Details':
                    set_step('Bus')
                    break;
                case 'Bus':
                    set_step('Payload')
                    break;
                default:
                    set_step('Details')
                    break;
            }
        }
    }
    const set_deployment_step_backword = () => {
        switch (step) {
            case 'Payload':
                set_step('Bus')
                break;
            case 'Bus':
                set_step('Details')
                break;
            default:
                set_step('Details')
                break;
        }
    }


    const deploy = () => { }


    return (
        <div className={`deploy__model ${open && 'active'}`}>
            <div className="deploy__model__center large">
                <div className="deploy__model__center__header">
                    <span className="fs-20">Quick Deploy</span>
                    <button className="header__close__button" onClick={close}>
                        <ModalClose />
                    </button>
                </div>
                <div className='deploy__model__center__body'>
                    <div className='model__center__body__container'>
                        <div className='navigation__header'>
                            {NAVIGATION_ITEMS.map((item, index) => (
                                <button
                                    key={index}
                                    disabled={disabled__navigation.includes(item.name)}
                                    className={`navigation__header__item ${step === item.name && 'active'} `}
                                    onClick={() => set_step(item.name)}
                                >
                                    {item.icon}
                                    <span>
                                        {item.name}
                                    </span>
                                </button>
                            ))}
                        </div>
                        {step === 'Details' &&
                            <DetailsForm
                                satellite={satellite}
                                set_satellite={set_satellite}
                                scenario_name={scenario_name}
                                all_satellites={all_satellites}
                                deployment_type={deployment_type}
                                set_scenario_name={set_scenario_name}
                                set_deployment_type={set_deployment_type}
                            />
                        }
                        {step === 'Bus' &&
                            <BusForm
                                bus_type={bus_type}
                                set_bus_type={set_bus_type}
                            />
                        }
                    </div>
                </div>
                <div className="deploy__model__center__footer">
                    <button className="footer__close__button"
                        onClick={() => {
                            step === 'Details' ? close() : set_deployment_step_backword()
                        }}>
                        {step === 'Details' ? 'Close' : 'Back'}
                    </button>
                    <button className="footer__next__button" onClick={() => {
                        deployment_type.value === 'HWIL' ? set_deployment_step_forword() : deploy()
                    }}>
                        {deployment_type.value === 'HWIL' ? 'Next' : 'Generate SDK'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QuickDeployModal