import { FC, Fragment, useMemo } from "react";
import useOrbitParams from "../../hooks/useOrbitParams";
import CustomLoader from "../../custom_components/CustomLoader";
import { get_formated_time } from "../../utils";
import useSatellite from "../../hooks/useSatellite";

interface Props {
    close: (e: any) => void;
    open: boolean;
}

const ScenariInfoContainer: FC<Props> = ({ open, close }) => {

    const { selected_satellite } = useSatellite()
    const orbit_details = useMemo(() => selected_satellite?.value?.orbitParameters, [selected_satellite])

    return (
        <div className={`scenario__orbit__info__container ${open && 'active'}`}>
            <div className="scenario__orbit__info__header">
                <span className="info__title">Orbit Details</span>
                <button className="close_button" onClick={close}>
                    <i className="fe fe-x fs-16 close_icon" />
                </button>
            </div>
            <div className="scenario__orbit__info__details__container">
                <div className="container__left__section">
                    <div className="info__details">
                        <span>{orbit_details?.['epoch'] && `${orbit_details?.['epoch'] && get_formated_time(orbit_details?.['epoch'])}`}</span>
                        <span>Epoch</span>
                    </div>
                    <div className="info__details">
                        <span>{orbit_details?.['altitude']} km</span>
                        <span>Altitude</span>
                    </div>
                    <div className="info__details">
                        <span>{orbit_details?.['eccentricity']}</span>
                        <span>Eccentricity</span>
                    </div>
                    <div className="info__details">
                        <span>{orbit_details?.['RAAN']}°</span>
                        <span>Longitude of ascending node</span>
                    </div>
                </div>
                <div className="container__right__section">

                    <div className="info__details">
                        <span>{orbit_details?.['TA']}°</span>
                        <span>True Anomaly</span>
                    </div>
                    <div className="info__details">
                        <span>{orbit_details?.['inclination']}°</span>
                        <span>Inclination</span>
                    </div>
                    <div className="info__details">
                        <span>{orbit_details?.['AP']}°</span>
                        <span>Argument of periapsis</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScenariInfoContainer