//@ts-nocheck
import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { containsSpecialChars, containsWhitespace } from '../../CommonComponents/CommonFunctions';
import { toast } from 'react-toastify';
import Dialog from '../../CommonComponents/Dialog';

const CreateSatModel = ({ close, satellite_name, setSatellite_name, satellite_des, setSatellite_des, onclick, type }) => {
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [isClicked, setIsClicked] = React.useState(false);
    const [isShowModal, setIsShowModal] = React.useState<any>(false)

    const handle_click = () => {
        setIsClicked(true)
        if (containsWhitespace(satellite_name) || satellite_name === '') {
            setError(true)
            toast.error('Please enter satellite name', { toastId: '0' })
            setErrorMsg('Please enter satellite name')
            setIsClicked(false)
            return
        } else {
            onclick()
        }
    }

    return (
        <div className='admin-form'>
            <div className='admin-form-card'>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <span className='form-heade-text' >{type} {type === 'Create' ? 'New' : ''} Satellite</span>
                    <i onClick={() => { setIsShowModal(true); }} className="fe fe-x-circle me-1 fs-25" style={{ cursor: 'pointer' }}></i>
                </div>
                <Form validated={error} className='d-flex flex-column gap-4'>
                    <div className={`d-flex gap-3 align-items-start w-100 ${type === 'Clone' && 'flex-column'}`}>
                        <span className='form-content-text'>{'Satellite Name * :'}</span>
                        <div className={`d-flex gap-2 flex-column ${type === 'Clone' ? 'w-100' : 'w-70'}`}>
                            <Form.Control
                                className={`${type === 'Clone' ? 'w-90' : 'w-75'}`}
                                required
                                type="text"
                                placeholder="Enter satellite name"
                                value={satellite_name}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        if (satellite_name?.length <= 26) {
                                            handle_click()
                                        }
                                    }
                                }}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let isNotValidName = containsSpecialChars(event.target.value)
                                    if (isNotValidName) {
                                        toast.dismiss()
                                        toast.error('Satellite name cannot contain special characters', { toastId: 'o' })
                                    } else {
                                        if (event.target.value.length > 26) {
                                            toast.dismiss()
                                            toast.error('Satellite name cannot exceed 26 characters', { toastId: 'o' })
                                        } else {
                                            setSatellite_name(event.target.value.trimStart());
                                        }
                                    }
                                    setError(false);
                                    setErrorMsg('');
                                    setIsClicked(false);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
                        </div>
                    </div>
                    {type !== 'Clone' &&
                        <div className='d-flex align-items-start gap-3 w-100'>
                            <span className='form-content-text'>{'Description :'}</span>
                            <div className='d-flex gap-2 flex-column w-70 ms-6'>
                                <Form.Control
                                    className='w-100'
                                    as="textarea"
                                    placeholder="Enter satellite description"
                                    value={satellite_des}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        setSatellite_des(event.target.value.trimStart());
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                            if (satellite_des?.length <= 100) {
                                                handle_click()
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                </Form>

                <div className='w-100 d-flex align-content-end justify-content-center mt-5'>
                    <Button
                        disabled={isClicked}
                        variant="primary"
                        type="button"
                        className="d-flex align-items-center justify-content-center btn btn-primary w-25 pt-1 pb-1 pe-4 ps-4 fs-14"
                        onClick={(e) => {
                            e.preventDefault();
                            handle_click()
                        }}
                    >
                        {type} {type === 'Clone' ? 'Satellite' : '& Continue'}
                    </Button>
                </div>
            </div>
            <Dialog
                onClick={(event) => {
                    if (event) {
                        close()
                    }
                    setIsShowModal(false);
                }}
                openModel={isShowModal}
                comment="Are you sure you want to discard the changes?"
            />
        </div>
    )
}

export default CreateSatModel