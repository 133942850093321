export const NavigationButton = [
    {
        label: 'Onboard Schedule',
        name: 'plan-onboard-schedule',
        used_by: ['operate', 'simulation', 'summary']
    },
    {
        label: 'Control Schedule',
        name: 'plan-control-schedule',
        used_by: ['operate']
    },
    // {
    //     label: 'Ground Contacts',
    //     name: 'plan-ground-contact',
    //     used_by: ['operate', 'simulation', 'summary']
    // },
]

export const get_navigator_button_by_dashboard = (dashboard: string) => {
    return NavigationButton.filter(item => item.used_by.includes(dashboard))
}