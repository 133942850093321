import { useEffect, useState } from "react"
import CommandCenterService from "../../../service/CommandCenterService"
import useUrlParams from "./useUrlParams"
import useError from "./useError"
import useScenario from "./useScenario"
import useSatellite from "./useSatellite"

type Orbit = {
    documentMetadata: any,
    groundStation: any,
    areaOfInterest: any,
    groundTarget: any,
    satToGsConnection: any,
    satToGTConnection: any,
    satellite: any
}

const useOrbit = () => {
    // const [orbitData, setOrbitData] = useState<Orbit | null>(null)
    const { dashboard, scenario_id, mission_id } = useUrlParams()
    const { selected_satellite, orbitData } = useSatellite()
    const { setError } = useError()

    // const getOrbitDetails = () => {
    //     const propagation = dashboard === 'summary' ? 'summary' : 'dashboard'
    //     CommandCenterService.getOribtData(scenario_id, truetwin_id, propagation).then(res => {
    //         if (res?.data) {
    //             setOrbitData(res?.data)
    //         }
    //     }).catch(err => {
    //         const error_msg = err?.message
    //         const error_code = error_msg === 'Network Error' ? error_msg : '500'
    //         // setError(error_code)
    //     })
    // }

    const getOrbitDetails = () => {
        // const propagation = 'scenario'
        // CommandCenterService.getnewOrbitData(mission_id, scenario_id, propagation).then(res => {
        //     if (res?.data) {
        //         setOrbitData(res?.data)
        //     }
        // }).catch(err => {
        //     const error_msg = err?.message
        //     const error_code = error_msg === 'Network Error' ? error_msg : '500'
        //     // setError(error_code)
        // })
    }

    useEffect(() => {
        if (dashboard && scenario_id) {
            getOrbitDetails()
        }
    }, [dashboard, scenario_id])

    return { orbitData }
}

export default useOrbit