import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from "./RadialSeparators";

interface CircularProgressbarProps {
    simulation_start_time: number;
    simulation_end_time: number;
    simulation_current_time: number;
}

const progressStyles = {
    progress__container: {
        display: 'flex',
        height: '24px',
        width: '24px',
        marginRight: '3px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column' as const,
        position: 'relative' as const,
    },
    progress__circle: {
        height: '20px',
        width: '20px',
        position: 'absolute' as const,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center' as const,
    }
};

const CircularProgressbar: React.FC<CircularProgressbarProps> = ({ simulation_start_time, simulation_end_time, simulation_current_time }) => {

    return (
        <div style={progressStyles.progress__container}>
            <CircularProgressbarWithChildren
                value={simulation_current_time}
                minValue={simulation_start_time}
                maxValue={simulation_end_time}
                text={``}
                strokeWidth={15}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathTransitionDuration: 0.5,
                    textColor: '#F0F0F0',
                    pathTransition: "none",
                    trailColor: "rgba(240, 240, 240, 0.2)",
                    pathColor: `#CCF54E`,
                })}
            >
                {RadialSeparators({
                    count: 20,
                    style: {
                        background: "black",
                        width: "1.5px",
                        height: `${30}%`
                    }
                })}
            </CircularProgressbarWithChildren>
            <div style={progressStyles.progress__circle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                    <path d="M3.2579 0.0459446L0.171471 1.81893C0.119137 1.84914 0.0757167 1.89265 0.0456058 1.94504C0.0154948 1.99743 -0.000237204 2.05685 2.70333e-06 2.11728V5.88958C-0.000237204 5.95001 0.0154948 6.00943 0.0456058 6.06182C0.0757167 6.11421 0.119137 6.15772 0.171471 6.18793L3.2579 7.95406C3.31003 7.98416 3.36917 8 3.42937 8C3.48956 8 3.5487 7.98416 3.60083 7.95406L6.68726 6.18793C6.73959 6.15772 6.78302 6.11421 6.81313 6.06182C6.84324 6.00943 6.85897 5.95001 6.85873 5.88958V2.11728C6.85897 2.05685 6.84324 1.99743 6.81313 1.94504C6.78302 1.89265 6.73959 1.84914 6.68726 1.81893L3.60083 0.0459446C3.5487 0.0158456 3.48956 0 3.42937 0C3.36917 0 3.31003 0.0158456 3.2579 0.0459446ZM3.07271 7.0247L0.672158 5.65295V2.68998L3.07271 4.06173V7.0247ZM3.41565 3.5679L0.949936 2.15157L3.41565 0.748964L5.88822 2.16186L3.41565 3.5679ZM6.15914 5.65638L3.75858 7.02813V4.06173L6.15914 2.68998V5.65638Z" fill="#F0F0F0" />
                </svg>
            </div>
        </div>
    )
}

export default CircularProgressbar