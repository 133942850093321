export const DialougeSvg = ({ type }: { type?: string }) => {
    if (type === 'delete') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path d="M11 14.9394L11.0039 15.1863M11 7.03998L11.0039 11.9771M20.0246 18.8891H1.97545C1.22515 18.8891 0.756605 18.0652 1.13253 17.4069L10.1571 1.60402C10.5322 0.947099 11.4678 0.947097 11.8429 1.60402L20.8675 17.4069C21.2434 18.0652 20.7749 18.8891 20.0246 18.8891Z" stroke="#FF3333" stroke-width="0.987427" stroke-linecap="round" />
            </svg>
        )
    } else if (type === 'update') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path d="M11 14.9394L11.0039 15.1863M11 7.03998L11.0039 11.9771M20.0246 18.8891H1.97545C1.22515 18.8891 0.756605 18.0652 1.13253 17.4069L10.1571 1.60402C10.5322 0.947099 11.4678 0.947097 11.8429 1.60402L20.8675 17.4069C21.2434 18.0652 20.7749 18.8891 20.0246 18.8891Z" stroke="#FF3333" stroke-width="0.987427" stroke-linecap="round" />
            </svg>
        )
    } else if (type === 'discard') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path d="M11 14.9394L11.0039 15.1863M11 7.03998L11.0039 11.9771M20.0246 18.8891H1.97545C1.22515 18.8891 0.756605 18.0652 1.13253 17.4069L10.1571 1.60402C10.5322 0.947099 11.4678 0.947097 11.8429 1.60402L20.8675 17.4069C21.2434 18.0652 20.7749 18.8891 20.0246 18.8891Z" stroke="#F2A93B" stroke-width="0.987427" stroke-linecap="round" />
            </svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path d="M11 14.9394L11.0039 15.1863M11 7.03998L11.0039 11.9771M20.0246 18.8891H1.97545C1.22515 18.8891 0.756605 18.0652 1.13253 17.4069L10.1571 1.60402C10.5322 0.947099 11.4678 0.947097 11.8429 1.60402L20.8675 17.4069C21.2434 18.0652 20.7749 18.8891 20.0246 18.8891Z" stroke="#CCF54E" stroke-width="0.987427" stroke-linecap="round" />
            </svg>
        )
    }
}