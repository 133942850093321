// @ts-nocheck

import { createElement, useEffect, useRef, useState } from "react";
import './FileQueue.scss'
import { CollapseSvg, PinSvg, FilequeueSvg, TaskCompleteSvg, TaskOngoingSvg, TaskUpcommingSvg, DownloadSvg } from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import { useSearchParams } from "react-router-dom";
import { Resizable } from 'react-resizable';
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import useUrlParams from "../../../../hooks/useUrlParams";
import { CircularProgress } from '@mui/material';
import CommandCenterService from "../../../../../../service/CommandCenterService";
import { downloadFile } from "../../../../../CommonComponents/CommonFunctions";
import useDownloadFile from "../../../../hooks/useDownloadFiles";
import EmptyPage from "../../../../custom_components/EmptyPage";
import { get_formated_time } from "../../../../utils";

const FileQueue = () => {
    const { dashboard } = useUrlParams()
    const { downloadFiles } = useDownloadFile()
    const [searchParams] = useSearchParams();
    const [modal, setModal] = useState({
        open: false,
        pinned: false,
        current_modal: ''
    })
    const [resizing, setResizing] = useState(false)
    const [height, setHeight] = useState(300)
    const [openUploadFile, setOpenUploadFile] = useState<string | boolean>(false)
    const [fileDownloading, setFileDownloading] = useState<string | boolean>(false)

    const [expand, setExpand] = useState(false);
    const { handleUpdateUrl, getModalDetails } = useModals()

    useEffect(() => {
        const details = getModalDetails('filequeue')
        setModal(details)
    }, [searchParams])

    const open_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('filequeue', 'pinned')
        } else {
            handleUpdateUrl('filequeue', 'open')
        }
    }

    const pinned_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('filequeue', 'open')
        } else {
            handleUpdateUrl('filequeue', 'pinned')
        }
    }

    const close_modal = (event: any) => {
        event.stopPropagation()
        handleUpdateUrl('filequeue', 'close')
    }

    const onResize = (event, { node, size, handle }) => {
        event.stopPropagation()
        if (size.height >= 150 && size.height <= 550) {
            setHeight(size.height);
        }
    };

    const onResizeStart = (events) => {
        events.stopPropagation()
        setResizing(true);
    }
    const onResizeStop = (events) => {
        events.stopPropagation()
        setResizing(false);
    }


    useEffect(() => {
        const close_modal = () => {
            const details = getModalDetails('filequeue')
            if (details.open && !details.pinned) {
                handleUpdateUrl('filequeue', 'close')
            }
        }
        window.addEventListener('click', close_modal)
        return () => {
            window.removeEventListener('click', close_modal)
        }
    }, [])

    const UploadFilesData = [
        {
            name: 'File_01_23102024',
            time: '21 April, 2024 11:50 UTC',
            status: 'complete',
            fileid: '1'
        },
        {
            name: 'File_02_231020248736873',
            time: '21 April, 2024 11:50 UTC',
            status: 'ongoing',
            fileid: '2'
        },
        {
            name: 'File_03_23102024',
            time: '21 April, 2024 11:50 UTC',
            status: 'upcomming',
            fileid: '3'
        }
    ]


    const getIcon = (status) => {
        if (status === 'complete') {
            return <TaskCompleteSvg />
        } else if (status === 'ongoing') {
            return <TaskOngoingSvg />
        } else {
            return <TaskUpcommingSvg />
        }
    }

    const get_true_twin_file_content_from_datastore = (file: any,) => {
        // setFileDownloading(file?.['name'])
        // CommandCenterService.getTrueTwinDataStoreFilecontent(truetwin_id, file?.['name'])?.then((data) => {
        //     if (data && data.data) {
        //         downloadFile(data?.data, file?.['name'])
        //         setFileDownloading('')
        //     }
        // }).catch((err) => {
        //     console.error(err);
        //     setFileDownloading('')
        // });
    }

    return (
        <Modal
            open={modal.open}
            pinned={modal.current_modal === 'filequeue' && modal.pinned}
            disabled={modal.current_modal === 'filequeue' && modal.pinned || resizing}
            class_name={`${modal.current_modal === 'filequeue' ? 'current__modal' : ''}`}
        >
            <div className={`filequeue__panel__container ${expand && 'expand'} `} onClick={open_modal}>
                <div className="filequeue__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <FilequeueSvg color={"#CCF54E"} />
                        <span className="panel__header__title">Files</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`} onClick={pinned_modal}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`} onClick={close_modal}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="filequeue__panel__body" >
                    {dashboard === 'operate' &&
                        <Resizable
                            className="position-relative"
                            width={expand ? 800 : 350}
                            height={height}
                            onResizeStop={onResizeStop}
                            onResizeStart={onResizeStart}
                            onResize={onResize}
                            handle={(handleAxis, ref) => <div ref={ref} className={`resize__handler handle-${handleAxis}`} />}>
                            <div className="file__upload__section" style={{ height: height }}>
                                <span className="color-atmos">Upload Queue</span>
                                {UploadFilesData?.length > 0 &&
                                    <div className="file__upload__scroll__section">
                                        {UploadFilesData.map(file => {
                                            const status = file.status
                                            const fileId = file.fileid
                                            const file_time = file.time ? get_formated_time(new Date(file.time).getTime()) : ''
                                            return (
                                                <div className={`file__details__container ${status} ${openUploadFile === fileId && 'active'}`}>
                                                    <div className="file__details__header">
                                                        <div className="d-flex align-items-center gap-2 w-50">
                                                            {getIcon(status)}
                                                            <span className="file__title">
                                                                <CustomToolTip title={file.name} placement="top">
                                                                    {file.name}
                                                                </CustomToolTip>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-end w-50 gap-2">
                                                            <span className="file__time">
                                                                <CustomToolTip title={file_time} placement="top">
                                                                    {file_time}
                                                                </CustomToolTip>
                                                            </span>
                                                            <button className="bg-transparent p-0"
                                                                onClick={() => { setOpenUploadFile(openUploadFile === fileId ? false : fileId) }}>
                                                                <i className={`fe fe-eye icon`} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="file__details__body"></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {UploadFilesData?.length === 0 &&
                                    <div className="h-100 w-100 align-items-center">
                                        <EmptyPage content_type="file" content={expand ? "File Queue is Empty" : ''} />
                                    </div>
                                }
                            </div>
                        </Resizable>
                    }
                    <div className="file__download__section">
                        <span className="color-atmos">List of Files</span>
                        {downloadFiles?.length > 0 &&
                            <div className="file__download__scroll__section">
                                {downloadFiles.map((file: { name: string, time: any }, index) => {
                                    const name = file.name
                                    const time = file?.['time'] ? get_formated_time(new Date(file?.['time']).getTime()) : ''
                                    return (
                                        <div className={`file__details__container ${fileDownloading === name && 'active'}`}>
                                            <div className="file__details__header">
                                                <div className="file__title__wrapper">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <FilequeueSvg color={"#F0F0F0"} />
                                                        <span className="file__title">
                                                            <CustomToolTip title={name} placement="top">
                                                                {name}
                                                            </CustomToolTip>
                                                        </span>
                                                    </div>
                                                    <span className="file__time">{time}</span>
                                                </div>
                                            </div>
                                            <button className="file__queue__btn"
                                                onClick={() => { get_true_twin_file_content_from_datastore(file) }}>
                                                {fileDownloading === name && <CircularProgress size={15} />}
                                                {!fileDownloading && fileDownloading !== name && <DownloadSvg />}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {downloadFiles?.length === 0 &&
                            <div className="h-100 w-100 align-items-center d-flex">
                                <EmptyPage content_type="file" content={"No Files"} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default FileQueue