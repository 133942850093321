import { BusSvg, DetailsSvg, PayloadSvg } from "./svgs/Svgs";

export const get_formated_time = (time: number) => {
    const date = new Date(time * 1000);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export const NAVIGATION_ITEMS = [
    {
        id: 1,
        name: 'Details',
        icon: <DetailsSvg />
    },
    {
        id: 2,
        name: 'Bus',
        icon: <BusSvg />
    },
    {
        id: 3,
        name: 'Payload',
        icon: <PayloadSvg />
    }
]

