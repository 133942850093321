import * as Cesium from 'cesium'
import { useEffect, useMemo, useState } from 'react'
import useScenario from '../hooks/useScenario'
import ScenarioTimeline from './ScenarioTimeline'
import './Visualization.scss'
import useViewer from '../hooks/useViewer'
import { get_ground_station_by_id } from '../utils'
import GroundStationCard from '../../../CommonComponents/GroundStationCard'
import GroundTargetCard from '../../../CommonComponents/GroundTargetCard'
import { removed_focus_gs } from './cesium_utils'
import ViewerSettings from '../../../CommonComponents/ViewerSettings'

const Visualization = () => {

    const { viewer, focused_ground_station, set_focused_ground_station } = useViewer()
    const { loading, schedule_data, all_ground_stations, ground_stations, task_plan } = useScenario()

    const generating_schedule = useMemo(() => loading === 'Generating', [loading])
    const show_timeline = useMemo(() => (schedule_data || generating_schedule) ? 'show__timeline' : '', [schedule_data, generating_schedule])
    const show_schedule_data = useMemo(() => (schedule_data && !generating_schedule), [schedule_data])

    const [selected_gs, set_selected_gs] = useState<any>(null)
    const [selected_task, set_selected_task] = useState<any>(null)


    useEffect(() => {
        if (!viewer || !all_ground_stations) {
            return;
        }
        const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        handler.setInputAction((movement: { endPosition: Cesium.Cartesian2; }) => {
            if (!viewer || !viewer.scene) return;

            const pickedObject = viewer.scene.pick(movement.endPosition);
            if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                let entity = pickedObject.id;
                all_ground_stations?.map((station) => {
                    if (entity?._id === station.groundStationId) {
                        const station = get_ground_station_by_id(all_ground_stations, entity?._id);
                        set_selected_gs(station)
                    }
                })
                task_plan && task_plan.value?.['taskDetails']?.map((target: any) => {
                    if (entity?._id === target?.refId && target?.groundTarget?.type === "Target Track") {
                        set_selected_task(target?.groundTarget)
                    }
                })
                viewer.selectedEntity = entity;
            } else {
                set_selected_task(null)
                set_selected_gs(null)
                document.body.style.cursor = 'auto';
            }

        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        return () => {
            handler.destroy();
        };
    }, [viewer, all_ground_stations, task_plan, selected_gs])

    return (
        <div className={`scenario__visualization__container ${show_timeline}`}>
            <div className='visualization__section'>
                <div id="scenariocesiumContainer" className="d-flex h-100 w-100"
                    onClick={() => {
                        if (focused_ground_station) {
                            set_focused_ground_station(null)
                            removed_focus_gs(viewer, ground_stations)
                        }
                    }} />
                <GroundStationCard station={selected_gs} viewer={viewer} />
                <GroundTargetCard target={selected_task} viewer={viewer} />
                <ViewerSettings viewer={viewer} />
            </div>
            <div className='timeline__section' style={loading === 'Deploying' ? { pointerEvents: 'none', opacity: '0.5', cursor: 'none' } : {}}>
                {generating_schedule &&
                    <div className='h-100 w-100 d-flex flex-column gap-3 scenario_timeline_container p-4'>
                        <div className='timeline_skeleton h-40' />
                        <div className='timeline_skeleton h-150' />
                    </div>
                }
                {show_schedule_data &&
                    <ScenarioTimeline />
                }
            </div>
        </div>
    )
}

export default Visualization