import React, { useContext } from 'react'
import { ScenarioContext } from '../providers/ScenarioProvider';

const useSatellite = () => {
    const context = useContext(ScenarioContext);
    if (context === undefined) {
        throw new Error('useSatellite must be used within a Satellite Provider');
    }
    const { unDeployLoader, setUnDeployLoader, satellites, selected_satellite, set_selected_satellite, orbitData } = context;

    return { unDeployLoader, setUnDeployLoader, satellites, selected_satellite, set_selected_satellite, orbitData }
}

export default useSatellite