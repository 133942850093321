import { useMemo } from 'react'
import useScenario from '../../../hooks/useScenario'
import useUrlParams from '../../../hooks/useUrlParams'
import StepHeader from './StepHeader'
import { SatelliteSvg } from '../../../svgs/Svgs'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip'
import useModals from '../../../hooks/useModals'

const AdditionObject = () => {

    const { scenario_step, navigate_scenario_step } = useUrlParams()
    const { loading, additional_orbits, scenario_response } = useScenario()
    const { set_open_additional_orbit_modal } = useModals()

    const active = useMemo(() => scenario_step === 'additional_object' ? 'active' : '', [scenario_step])
    const completed = useMemo(() => scenario_response?.['externalAttachedOrbits'], [scenario_response])
    const disabled = useMemo(() => (loading || (!completed && !active)) ? 'disabled' : '', [loading, completed, active])

    return (
        <section id='additional_object' className={`dropdown__container position-relative ${active} ${disabled}`}>
            <StepHeader
                step='Additional Objects'
                completed={completed}
                onClick={() => { navigate_scenario_step('additional_object') }}
                button={active ?
                    <button className='py-1 pe-3 h-auto bg-black rounded-2 d-flex gap-2 align-items-center opacity-50' style={{ pointerEvents: "none" }} onClick={() => set_open_additional_orbit_modal(true)}>
                        <i className='fe fe-plus' />
                        <span className='fs-14'>New</span>
                    </button>
                    : undefined
                }
            />
            <div className='dropdown__container__body'>
                {additional_orbits.map((orbit, index) => (
                    <button className={`p-0 h-auto bg-transparent d-flex align-items-center justify-content-between w-100 satellite__${index}`} key={index}>
                        <div className='d-flex align-items-center gap-1 opacity-75 w-90'>
                            <SatelliteSvg />
                            <span className='fs-12 text-truncate'>
                                <CustomToolTip title={`${orbit?.['name']}`} placement='top'>
                                    {orbit?.['name']}
                                </CustomToolTip>
                            </span>
                        </div>
                        <ButtonGroup size="sm" className="flex-nowrap mb-0">
                            <div className="btn-list more-button">
                                <Dropdown drop="end">
                                    <Dropdown.Toggle
                                        size="sm"
                                        variant="transparent"
                                        disabled={false}
                                    >
                                        <i className="fe fe-more-vertical fs-14"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='button__dropdown__container'>
                                        <div className='bg-black rounded-2'>
                                            <Dropdown.Item eventKey="0"
                                                className='button__dropdown__item'
                                                disabled={false}
                                                onClick={(e) => {
                                                }}
                                            >
                                                Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="2"
                                                className='button__dropdown__item'
                                                disabled={false}
                                                onClick={(e) => {
                                                }}
                                            >
                                                Delete
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </ButtonGroup>
                    </button>
                ))}
            </div>
        </section>
    )
}

export default AdditionObject