import './ConfirmationDialog.scss'
import { DialougeSvg } from './svgs/Svgs'

interface ConfirmationDialogProps {
    type: 'discard' | 'delete' | 'update'
    open: boolean
    title: string
    description: string
    onConfirm: () => void
    onCancel: () => void
    onDiscard?: () => void
    confirm_button_text: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ type, open, title, description, onConfirm, onCancel, confirm_button_text, onDiscard }) => {
    return (
        <div className={`confirmation__dialog__container ${open ? 'open' : 'close'}`}>
            <div className={`confirmation__dialog__center__container ${type}`}>
                <div className='confirmation__dialog__header'>
                    <div className='d-flex align-items-center gap-3'>
                        <DialougeSvg type={type} />
                        <span className='confirmation__dialog__title'>{title}</span>
                    </div>
                    <button className='close__button' onClick={onCancel}>
                        <i className='fe fe-x' />
                    </button>
                </div>
                <div className='confirmation__dialog__body'>{description}</div>
                <div className='confirmation__dialog__footer'>
                    <button className='cancel__button'
                        onClick={() => {
                            type === 'discard' ? onDiscard?.() : onCancel()
                        }}
                    >{type === 'discard' ? 'Discard' : 'Cancel'}</button>
                    <div className='d-flex align-items-center gap-2'>
                        <button className='save_closee__button'
                            onClick={onConfirm}
                        >{confirm_button_text}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationDialog