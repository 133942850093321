
import CustomSelect from '../../../../../CommonComponents/CustomSelect'
import useScenario from '../../../hooks/useScenario'
import useUrlParams from '../../../hooks/useUrlParams'
import StepHeader from './StepHeader'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import { getYears } from '../../../utils';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { CloseSvg, ImportSvg } from '../../../svgs/Svgs';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import useViewer from '../../../hooks/useViewer';
import { selected_aoi_packet } from '../../../visualization/cesium_utils';

dayjs.extend(utc)

const Schedule = () => {
    const { viewer } = useViewer()
    const { scenario_step, navigate_scenario_step, mission_id, scenario_id } = useUrlParams()
    const { loading, imported_schedule, duration, all_task_plans, task_plan, schedule_data, simulation_start_time, set_form_data } = useScenario()

    const active = useMemo(() => (scenario_step === 'schedule' || scenario_step === 'deploy') ? 'active' : '', [scenario_step])
    const completed = useMemo(() => schedule_data ? true : false, [schedule_data])
    const disabled = useMemo(() => (loading || (!completed && !active)) ? 'disabled' : '', [loading, completed, active])
    const show_imported_schedule = useMemo(() => imported_schedule, [imported_schedule])

    const set_simulation_start_time = (value: any) => {
        set_form_data('simulation_start_time', dayjs.utc(value).unix())
    }

    return (
        <section id='schedule' className={`dropdown__container position-relative ${active} ${disabled}`}>
            <StepHeader
                step='Schedule'
                completed={completed}
                onClick={() => { navigate_scenario_step('schedule') }}
                button={active ?
                    <button disabled className='py-1 pe-3 h-auto cursor-auto opacity-50 bg-black rounded-2 d-flex align-items-center' onClick={() => { }}>
                        <ImportSvg />
                        <span className='fs-14'>Import</span>
                    </button>
                    : undefined
                }
            />
            <div className='dropdown__container__body'>
                {show_imported_schedule &&
                    <div className='py-2 px-3 bg-black w-100 border rounded-2 d-flex align-items-center justify-content-between'>
                        <span className='w-90 fs-14 text-truncate'>
                            <CustomToolTip title={imported_schedule?.['name']} placement='top'>
                                {imported_schedule?.['name']}
                            </CustomToolTip>
                        </span>
                        <button className='header__button' onClick={() => { }}>
                            <CloseSvg />
                        </button>
                    </div>
                }
                {!show_imported_schedule &&
                    <div className='d-flex flex-column gap-1 w-100'>
                        <span className='opacity-50 fs-14'>Task Plan</span>
                        <CustomSelect
                            options={all_task_plans}
                            value={task_plan}
                            onChange={(e: { label: string, value: any }) => {
                                set_form_data('task_plan', e)
                                selected_aoi_packet(viewer, e.value, scenario_id, mission_id)
                            }}
                            placeholder='Select Task plan'
                        />
                    </div>
                }
                <div className='d-flex flex-column gap-1 w-100'>
                    <span className='opacity-50 fs-14'>Simulation Start Time</span>
                    <div className='d-flex align-items-center gap-2'>
                        <div className='d-flex align-items-center border rounded-1 overflow-hidden p-0 w-65'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DateTimePicker
                                    sx={{
                                        borderRadius: '0px',
                                        background: 'rgba(10, 10, 10, 0.65)!important',
                                        '.MuiFormControl-root': {
                                            paddingRight: '0px'
                                        },
                                        '.MuiInputBase-input': {
                                            fontSize: '14px !important',
                                        },
                                        '.MuiOutlinedInput-input': {
                                            fontSize: '14px !important',
                                        },
                                        '.MuiOutlinedInput-root': {
                                            height: '30px !important',
                                            paddingRight: '0px'
                                        },
                                        '.MuiInputAdornment-root': {
                                            display: 'none',
                                        },
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '0px',
                                            border: 'none',
                                            borderRight: '0.5px solid rgba(240,240,240,0.30) !important',
                                        }
                                    }}
                                    className="rounded-3"
                                    ampm={false} format='YYYY-MM-DD HH:mm:ss'
                                    shouldDisableYear={(date) => {
                                        const current_year = dayjs(date).year();
                                        const yearList = getYears(2002, 2037);
                                        return !yearList.includes(current_year);
                                    }}
                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                    value={dayjs.utc(simulation_start_time * 1000)} onChange={set_simulation_start_time} />
                            </LocalizationProvider>
                            <span className='opacity-75 fs-12 px-2'>UTC</span>
                        </div>
                        <div className='orbit__text__input__container bg-transparent w-35'>
                            <Form.Control
                                value={duration}
                                className="orbit__text__input"
                                type="number"
                                placeholder="Scenario title"
                                required
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => set_form_data('duration', e.target.value)}
                            />
                            <span className='parameter__section'>Mins</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Schedule