import { ReactNode, createContext, useEffect, useState } from "react";
import useUrlParams from "../hooks/useUrlParams";
import useError from "../hooks/useError";
import { SatelliteDetails, ScenarioResponseType, SelectedSatelliteType } from "../types";
import SimulateService from "../../../service/SimulateService";
import { toast } from "react-toastify";
import CommandCenterService from "../../../service/CommandCenterService";

type SimulationTime = {
    start_time: number;
    end_time: number;
    duration: number;
}

interface ScenarioContextType {
    scenario: ScenarioResponseType | null;
    satellites: SelectedSatelliteType[] | null;
    selected_satellite: SelectedSatelliteType | null;
    loading: boolean;
    unDeployLoader: boolean | {};
    setUnDeployLoader: (unDeployLoader: boolean | {}) => void;
    set_scenario: (scenario: ScenarioResponseType) => void;
    set_selected_satellite: (scenario: SelectedSatelliteType) => void;
    setLoading: (loading: boolean) => void;
    simulation_time: SimulationTime,
    set_simulation_time: (data: SimulationTime) => void;
    grafana: any;
    set_grafana: (data: any) => void;
    orbitData: any;
    setOrbitData: (data: any) => void
}

export const ScenarioContext = createContext<ScenarioContextType | undefined>(undefined);

interface ScenarioProviderProps {
    children: ReactNode;
}

const ScenarioProvider = ({ children }: ScenarioProviderProps) => {
    const { mission_id, scenario_id, dashboard, sat_id, sat_grp_id } = useUrlParams()
    const [scenario, set_scenario] = useState<ScenarioResponseType | null>(null);
    const [satellites, set_satellites] = useState<SelectedSatelliteType[] | null>(null);
    const [selected_satellite, set_selected_satellite] = useState<SelectedSatelliteType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [unDeployLoader, setUnDeployLoader] = useState<boolean | {}>(false);
    const [simulation_time, set_simulation_time] = useState<SimulationTime>({
        start_time: 0,
        end_time: 0,
        duration: 0
    })
    const [currentGraphanaUrl, setCurrentGrafanaUrl] = useState([])
    const [grafana, set_grafana] = useState([])
    const [orbitData, setOrbitData] = useState()

    const { setError } = useError()

    const get_simulation_time = (scenario: any) => {
        if (dashboard === 'operate') {
            const simulation_start_time = scenario?.['scenarioStartTime']
            const simulation_duration = scenario?.['scenarioDuration'] * 60
            const simulation_end_time = simulation_start_time + simulation_duration
            set_simulation_time({
                start_time: simulation_start_time,
                end_time: simulation_end_time,
                duration: simulation_duration
            })
        } else {
            const simulation_start_time = scenario?.['simulationStartTime']
            const simulation_duration = scenario?.['simulationDuration'] * 60
            const simulation_end_time = simulation_start_time + simulation_duration
            set_simulation_time({
                start_time: simulation_start_time,
                end_time: simulation_end_time,
                duration: simulation_duration
            })
        }
    }

    useEffect(() => {
        if (scenario_id) {
            // call api to get scenario details and satellites by scenario id parallaly
            SimulateService.getAllScenarioInfo(mission_id, scenario_id).then((res) => {
                set_scenario(res.data)
                // Mapping all satellites
                const satellites = res.data?.satellites.map((satellite: SatelliteDetails) => ({
                    label: satellite.satelliteName,
                    value: satellite
                }))
                set_satellites(satellites)
                get_simulation_time(res.data)
                const selected_satellite = satellites.find((satellite: SelectedSatelliteType) => satellite.value.satelliteId === sat_id)
                set_selected_satellite(selected_satellite)


                SimulateService.getGrafanaData(scenario_id, mission_id)?.then((grafanaRes) => {
                    if (grafanaRes?.data) {
                        setLoading(false)
                        setCurrentGrafanaUrl(grafanaRes?.data?.urls)
                    }
                }).catch((err) => {
                    toast.error("Somethiing went Wrong", { toastId: "error" })
                })

            }).catch((err) => {
                console.log(err)
            }).finally(() => {

            })
        }
    }, [scenario_id])


    useEffect(() => {
        if (scenario_id) {
            const propagation = 'scenario'
            CommandCenterService.getnewOrbitData(mission_id, scenario_id, propagation).then(res => {
                if (res?.data) {
                    setOrbitData(res?.data)
                }
            }).catch(err => {
                const error_msg = err?.message
                const error_code = error_msg === 'Network Error' ? error_msg : '500'
                // setError(error_code)
            })
        }
    }, [scenario_id])

    useEffect(() => {
        if (selected_satellite && currentGraphanaUrl) {
            const filteredSatellites = currentGraphanaUrl?.filter(
                (sat) => sat?.['SatelliteId'] === selected_satellite?.value?.satelliteId
            );
            set_grafana(filteredSatellites)
        }
    }, [selected_satellite, currentGraphanaUrl])


    return (
        <ScenarioContext.Provider value={{ scenario, loading, set_scenario, setLoading, satellites, selected_satellite, set_selected_satellite, unDeployLoader, setUnDeployLoader, simulation_time, set_simulation_time, grafana, set_grafana, orbitData, setOrbitData }}>
            {children}
        </ScenarioContext.Provider>
    )
}

export default ScenarioProvider