import { FC } from "react";
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import { contact_options, time_options } from "./utils";
import useUrlParams from "../../../../hooks/useUrlParams";
import PreviewSlider from "../../../../custom_components/PreviewSlider";
import useUrlNavigation from "../../../../hooks/useUrlNavigation";
import useTimeline from "../../../../hooks/useTimeline";

interface ContactHeaderProps {
  openSelector: string;
  setOpenSelector: (data: any) => void;
  selected_contact: {
    label: string;
    value: string | number;
  };

  select_contact_option: (data: any) => void;
  select_filter_contact_option: (data: any) => void;
  filterOptions: any[];
  filterContent: any[];
  selectedFilter: any;
  select_inner_option: (data: any) => void;
  setSelectedFilter: (data: any) => void;
}

const ContactHeader: FC<ContactHeaderProps> = ({
  openSelector,
  filterContent,
  filterOptions,
  selectedFilter,
  selected_contact,
  setOpenSelector,
  select_contact_option,
  select_filter_contact_option,
  setSelectedFilter,
  select_inner_option,
}) => {

  const { dashboard, truetwin_id } = useUrlParams();
  const { navigate_to } = useUrlNavigation()
  const { selected_timeframe, select_timeframe } = useTimeline()

  return (
    <div className='contact__center__container__header'>
      <div className={`contact__center__header__selector__container ${openSelector === 'contact_options' && 'active'}`}>
        <div className='selector__selected' onClick={(e: any) => {
          e.stopPropagation();
          setOpenSelector(openSelector === 'contact_options' ? '' : 'contact_options')
        }}>
          <span>{selected_contact.label}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.92943 0.508988C4.15259 0.274673 4.5144 0.274673 4.73756 0.508988L8.16613 4.10899C8.38929 4.3433 8.38929 4.7232 8.16613 4.95752C7.94297 5.19183 7.58116 5.19183 7.35801 4.95752L4.3335 1.78178L1.30899 4.95752C1.08583 5.19183 0.72402 5.19183 0.500864 4.95752C0.277707 4.7232 0.277707 4.3433 0.500864 4.10899L3.92943 0.508988ZM0.500864 7.70899C0.72402 7.47467 1.08583 7.47467 1.30899 7.70899L4.3335 10.8847L7.35801 7.70899C7.58116 7.47467 7.94297 7.47467 8.16613 7.70899C8.38929 7.9433 8.38929 8.3232 8.16613 8.55752L4.73756 12.1575C4.5144 12.3918 4.15259 12.3918 3.92943 12.1575L0.500864 8.55752C0.277707 8.3232 0.277707 7.9433 0.500864 7.70899Z" fill="#E3E3E3" />
          </svg>
        </div>
        <div className={`selector__option__container`}
          // style={selected_contact.value === 'ground_contact' ? { bottom: '45px' } : { top: '45px' }}
          style={{ bottom: '45px' }}
        >
          {contact_options.map((contact, index) => (
            <div key={index} className={`selector__option ${selected_contact.value === contact.value && 'active'}`} onClick={(e: any) => {
              e.stopPropagation();
              select_contact_option(contact)
            }}>{contact.label}</div>
          ))}
        </div>
      </div>


      <div className='d-flex align-items-center'>
        {dashboard === 'summary' && <PreviewSlider />}
        <div className={`contact__center__header__selector__container select ${openSelector === 'time_options' && 'active'}`}>
          <div className='selector__selected' onClick={(e: any) => {
            e.stopPropagation();
            setOpenSelector(openSelector === 'time_options' ? '' : 'time_options')
          }}>
            <span>{selected_timeframe.label}</span>
            <i className='fe fe-chevron-down icon' />
          </div>

          <div className={`selector__option__container`}
            // style={selected_contact.value === 'ground_contact' ? { bottom: '45px' } : { top: '45px' }}
            style={{ bottom: '45px' }}
          >
            {time_options.map((time, index) => {
              return (
                <div key={index}
                  className={`selector__option ${selected_timeframe.value === time.value && 'active'} ${(dashboard !== 'operate' && time.value === 180) && 'hide_option'}`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (selected_timeframe.value !== time.value) {
                      select_timeframe(time);
                      setOpenSelector('');
                    }
                  }}>{time.label}</div>
              )
            })}
          </div>
        </div>

        {dashboard === 'operate-0.1' &&
          <button className={`contact__center__header__button ${openSelector === 'filter_options' && 'active'}`}
            disabled
            onClick={(e: any) => {
              e.stopPropagation();
              setOpenSelector(openSelector === 'filter_options' ? '' : 'filter_options')
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
              <path d="M5.24976 9.00072H8.75024C8.97329 9.00097 9.18783 9.08638 9.35002 9.2395C9.51221 9.39262 9.60982 9.60189 9.62289 9.82456C9.63596 10.0472 9.56352 10.2665 9.42035 10.4375C9.27719 10.6086 9.07412 10.7185 8.85263 10.7448L8.75024 10.751H5.24976C5.02671 10.7507 4.81217 10.6653 4.64998 10.5122C4.48779 10.3591 4.39018 10.1498 4.37711 9.92713C4.36404 9.70446 4.43648 9.48521 4.57965 9.31416C4.72281 9.14312 4.92588 9.03319 5.14737 9.00685L5.24976 9.00072ZM3.49952 4.62512H10.5005C10.7235 4.62536 10.9381 4.71077 11.1003 4.86389C11.2625 5.01701 11.3601 5.22628 11.3731 5.44895C11.3862 5.67162 11.3138 5.89087 11.1706 6.06192C11.0274 6.23296 10.8244 6.34289 10.6029 6.36923L10.5005 6.37536H3.49952C3.27646 6.37511 3.06193 6.2897 2.89973 6.13658C2.73754 5.98347 2.63994 5.77419 2.62687 5.55153C2.6138 5.32886 2.68624 5.1096 2.8294 4.93856C2.97257 4.76751 3.17564 4.65759 3.39713 4.63124L3.49952 4.62512ZM0.874152 0.249512H13.1258C13.3489 0.249759 13.5634 0.335168 13.7256 0.488287C13.8878 0.641406 13.9854 0.850679 13.9985 1.07335C14.0116 1.29601 13.9391 1.51527 13.796 1.68631C13.6528 1.85736 13.4497 1.96728 13.2282 1.99363L13.1258 1.99975H0.874152C0.651101 1.99951 0.436563 1.9141 0.274371 1.76098C0.112179 1.60786 0.014577 1.39859 0.00150524 1.17592C-0.0115665 0.953252 0.0608791 0.733997 0.20404 0.562952C0.347202 0.391907 0.550273 0.281982 0.771762 0.255638L0.874152 0.249512Z" fill="#E3E3E3" />
            </svg>
            <div className={`contact__center__header__filter_selector ${selectedFilter.option !== '' && 'active'}`}
              // style={selected_contact.value === 'ground_contact' ? { bottom: '45px' } : { top: '45px' }}
              style={{ bottom: '45px' }}
            >
              {filterOptions.map((option, index) => (
                <div key={index} className={`filter_selector__option ${option.value === selectedFilter.option && 'active'}`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (selected_contact.value === 'ground_&_on_board') {
                      select_filter_contact_option(option);
                      setSelectedFilter({
                        option: selectedFilter.option === option.value ? '' : option.value,
                        value: []
                      });
                    }
                  }}>
                  <div className='selected__option'>
                    <span>{option.label}</span>
                    <i className='fe fe-chevron-down icon' />
                  </div>
                  <div className='filter_selector__inner__option__container'>
                    {filterContent.map((option, id) => (
                      <div key={id} className={`filter_selector__inner__option ${selectedFilter.value.includes(option.value) && 'active'}`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          select_inner_option(option)
                        }}
                      >
                        <div className='check__box'>
                          <i className='fe fe-check check' />
                        </div>
                        <span className='option__label'>
                          <CustomToolTip title={option.label} placement='top'>
                            {option.label}
                          </CustomToolTip>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </button>
        }


        <CustomToolTip title='Plan' placement='top'>
          <button className='contact__center__header__button' onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            navigate_to(selected_contact.value === 'ground_contact' ? 'plan-ground-contact' : 'plan-onboard-schedule', truetwin_id as string)
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5455 16.4991C14.9313 16.4991 15.3012 16.3458 15.574 16.0731C15.8468 15.8003 16 15.4304 16 15.0446V1.95446C16 1.56871 15.8468 1.19877 15.574 0.926002C15.3012 0.653237 14.9313 0.5 14.5455 0.5H1.45538C1.06963 0.5 0.699685 0.653237 0.426921 0.926002C0.154156 1.19877 0.000918596 1.56871 0.000918596 1.95446V7.04508C0.000918596 7.23795 0.0775375 7.42293 0.21392 7.55931C0.350302 7.69569 0.535276 7.77231 0.728149 7.77231C0.921023 7.77231 1.106 7.69569 1.24238 7.55931C1.37876 7.42293 1.45538 7.23795 1.45538 7.04508V1.95446H14.5455V15.0446H9.45492C9.26205 15.0446 9.07707 15.1212 8.94069 15.2576C8.80431 15.394 8.72769 15.579 8.72769 15.7718C8.72769 15.9647 8.80431 16.1497 8.94069 16.2861C9.07707 16.4225 9.26205 16.4991 9.45492 16.4991H14.5455ZM10.1822 7.04508V11.4085C10.1822 11.6013 10.1055 11.7863 9.96915 11.9227C9.83277 12.0591 9.64779 12.1357 9.45492 12.1357C9.26205 12.1357 9.07707 12.0591 8.94069 11.9227C8.80431 11.7863 8.72769 11.6013 8.72769 11.4085V8.80061L1.24303 16.2867C1.17541 16.3543 1.09514 16.408 1.0068 16.4446C0.918457 16.4812 0.823771 16.5 0.728149 16.5C0.632528 16.5 0.537842 16.4812 0.449499 16.4446C0.361156 16.408 0.280885 16.3543 0.21327 16.2867C0.145655 16.2191 0.0920201 16.1388 0.0554271 16.0505C0.0188342 15.9622 0 15.8675 0 15.7718C0 15.6762 0.0188342 15.5815 0.0554271 15.4932C0.0920201 15.4049 0.145655 15.3246 0.21327 15.257L7.69939 7.77231H5.09154C4.89866 7.77231 4.71369 7.69569 4.57731 7.55931C4.44092 7.42293 4.3643 7.23795 4.3643 7.04508C4.3643 6.8522 4.44092 6.66723 4.57731 6.53085C4.71369 6.39447 4.89866 6.31785 5.09154 6.31785H9.45492C9.55016 6.31746 9.64454 6.33587 9.73266 6.37201C9.82078 6.40815 9.90091 6.46131 9.96846 6.52845C10.036 6.59559 10.0897 6.6754 10.1263 6.7633C10.163 6.8512 10.182 6.94547 10.1822 7.04072" fill="#E3E3E3" />
            </svg>
          </button>
        </CustomToolTip>
      </div>
    </div >
  )
}

export default ContactHeader